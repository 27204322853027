import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '@app/shared/components';
import { TranslateService } from '@ngx-translate/core';
declare let numeral: any;
import moment from 'moment';
import { forkJoin, timer } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-client-payments',
  templateUrl: './client-payments.component.html'
})
export class ClientPaymentsComponent implements OnInit {
  public tableDataSorce = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dataTableComponent: Table;
  filteredItemsSize = -1;
  public clientName: string = null;
  searchTextStr: any = "";
  totalPaymentAmount = 0;
  totalRetainerBalance = 0;
  totalWriteOff = 0;
  tempTotalPaymentAmount = 0;
  tempTotalRetainerBalance = 0;
  tempTotalWriteOff = 0;
  payments: any = [];
  selectedPayment = null;
  clientID;
  isTransferAdvance: boolean = false;
  paymentSearch;
  public selectedCustomer: any = "";
  public nativeWindow: any;
  public companyIdRef: any;
  sidePanelDisplay = false;
  paymentDetailsSidebar: any = [];
  public showDebitMemoDialog: boolean = false;
  public debitMemoDesc: string = "";
  selectedRowData: any;
  public isClientInvoiceDisplay: boolean = false;
  public clientInvoiceList: any = [];
  public selectedPaymentToAssign: any = null;
  public selectedItemToAssign: any = [];
  public newTotalUnapplied: number = 0;
  public selectedOldBillingHeaderID: any = null;
  public selectedOldPaymentHeaderID: any = null;
  public selectedOldPaymentDetailID: any = null;
  public selectedAppliedAmount: number = 0;
  public clientsList: any = [];
  public filteredClients: any = [];
  public selClient: any = null;
  public paymentNote: any = null;
  intervalid: any;
  private filterTimeout: any = null;
  private filterTimer: any = timer(500);

  constructor(private mangoAPISrvc: MangoApiService, private http: HttpClient, public mangoUtils: mangoUtils, private encrDecSrvc: EncrDecrService, private breadcrumbService: BreadcrumbService, public sharedSrvc: SharedComponentsService, public translate: TranslateService) {
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);

    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.selectedCustomer = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    this.companyIdRef = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.searchTextStr = this.encrDecSrvc.getObject(AppConstants.Payments + '_' + AppConstants.SearchString);
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('client') },
      { label: this.translate.instant('client.payments') },
      { label: this.clientName, icon: 'ic-red' }
    ]);
  }

  ngOnInit(): void {
    this.getPayments();
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  fetchClients() {
    if (this.clientsList.length == 0 || this.clientsList.length !== this.encrDecSrvc.clientList.length) {
      const list = this.encrDecSrvc.clientList;
      const idArray = this.clientsList.map((client) => client.ClientID);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item["ContactRecord"] != true && !idArray.includes(item.ClientID)) {
          this.clientsList.push(item);
          this.filteredClients.push(item);
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(" ");
  }

  getClientInvoice(ClientID) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    const observableBatch = [];
    const urlOne = parent.http.get(`${environment.API_URL}/accounting/getOpenInvoicesById/[${ClientID}]`, { 'headers': headers });
    observableBatch.push(urlOne);
    forkJoin(observableBatch).subscribe(
      (data: any) => {
        this.clientInvoiceList = data[0].filter(itm => itm.InvoiceType !== 'Debit Memo').map((obj: any) => {
          obj['selected'] = false;
          obj['displayDate'] = moment(obj.InvoiceDate.substr(0, 10)).format("MM/DD/YYYY");
          obj.InvoiceBalance = obj.InvoiceBalance ? numeral(obj.InvoiceBalance).value() : 0;
          obj.PaymentsApplied = obj.PaymentsApplied ? numeral(obj.PaymentsApplied).value() : 0;
          return obj;
        });
        parent.mangoAPISrvc.showLoader(false);
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
      });

  }

  unapplyPayment(rowData) {
    this.preventImportedTransactions(rowData)
    if (["Retainer Payment", "Retainer"].includes(rowData['InvoiceType'])) {
      this.mangoAPISrvc.notify('error', "Error", "Cannot unapply this payment");
      return;
    }
    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: "Are you sure you want to unapply this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('No'),
      width: '38em',
      allowEscapeKey: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.unapplyPayment(rowData['PaymentDetailID'], rowData)
          .subscribe(result => {
            this.mangoAPISrvc.showLoader(false);
            this.getPayments(true);
          },(error: string) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify('error','Error', error);
          });
      }
    })
  }

  transferPayment(rowData) {
    this.preventImportedTransactions(rowData)
    if (["Retainer Payment", "Retainer"].includes(rowData['InvoiceType'])) {
      this.mangoAPISrvc.notify('error', "Error", "Cannot Assign this payment to another invoice");
      return;
    }

    this.newTotalUnapplied = rowData.AppliedAmount;
    this.selectedOldBillingHeaderID = rowData.BillingHeaderID;
    this.selectedOldPaymentHeaderID = rowData.PaymentHeaderID;
    this.selectedOldPaymentDetailID = rowData.PaymentDetailID;
    this.selectedAppliedAmount = rowData.AppliedAmount;
    this.paymentNote = rowData.DescriptionShort;
    this.isClientInvoiceDisplay = true;
  }


  transferPaymentToInvoice() {
    if (this.isTransferAdvance) {
      this.mangoAPISrvc.showLoader(true)
      this.mangoAPISrvc.transferAdvancePayment({
        BillingHeaderID: this.selectedOldBillingHeaderID,
        PaymentHeaderID: this.selectedOldPaymentHeaderID,
        ClientID: this.selClient?.ClientID
      }).subscribe(
        (result) => {
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify(
            "success",
            "Success",
            "Successfully transferred advance payment!"
          );
          this.getPayments()
          this.newTotalUnapplied = null;
          this.selectedOldBillingHeaderID = null;
          this.selectedOldPaymentHeaderID = null;
          this.selectedAppliedAmount = 0;
          this.isClientInvoiceDisplay = false;
          this.isTransferAdvance = false;
          this.selClient = {}
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify(
            "error",
            "Error",
            "Error occurred while transferring advance payment"
          );
        }
      );
    } else {
      if (this.newTotalUnapplied > 0) {
        let messageString = "";
        messageString += `<div>You have <strong>$${parseFloat(this.newTotalUnapplied.toString()).toFixed(2)} Unapply Payment</strong> that will be set back as Advance Payments to the selected Client.</div>`
        Swal.fire({
          title: 'Confirmation',
          html: messageString,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Yes, do it!',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            this.callAssignPayment();
          }
        })
      }
      else {
        this.callAssignPayment();
      }
    }
  }

  handleSelectClick(event) {
    this.selClient = event;
    this.getClientInvoice(event.ClientID);
  }

  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientsList.length; i++) {
        const client = this.clientsList[i];
        if (client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  disableButton() {
    if (this.isTransferAdvance) {
      return (!this.selClient?.ClientID)
    } else {
      return !(this.clientInvoiceList.filter(itm => itm.selected === true).length > 0);
    }
  }

  callAssignPayment() {
    this.mangoAPISrvc.showLoader(true);
    const processData = [];
    const selectedInvoices = this.clientInvoiceList.filter(itm => itm.selected);

    for (let i = 0; i < selectedInvoices.length; i++) {
      processData.push({
        PaymentsApplied: selectedInvoices[i]["PaymentsApplied"],
        NewBillingHeaderID: selectedInvoices[i]["BillingHeaderID"],
      })
    }

    const additionalUnapplied = selectedInvoices.reduce((a, b) => {
      return b['InvoiceType'] == 'Retainer Invoice' ? a + numeral(b['PaymentsApplied']).value() : a
    }, 0)

    const updateObj = {
      paymentData: processData,
      advancePayment: this.mangoUtils.roundOffDecimals(this.newTotalUnapplied),
      additionalUnapplied: (additionalUnapplied || 0),
      oldBillingHeaderID: this.selectedOldBillingHeaderID,
      oldPaymentHeaderID: this.selectedOldPaymentHeaderID,
      oldPaymentDetailID: this.selectedOldPaymentDetailID,
      AppliedAmount: this.mangoUtils.roundOffDecimals(this.selectedAppliedAmount),
      CurrentStaffID: this.encrDecSrvc.getObject(AppConstants.staffID),
      PaymentNote: this.paymentNote,
      ClientID: this.selClient?.ClientID,
      CompanyID: this.encrDecSrvc.getObject(AppConstants.companyID)
    }

    this.mangoAPISrvc.assignPaymentToInvoice(updateObj).subscribe((result) => {
      this.newTotalUnapplied = null;
      this.selectedOldBillingHeaderID = null;
      this.selectedOldPaymentHeaderID = null;
      this.selectedOldPaymentDetailID = null;
      this.selectedAppliedAmount = 0;
      this.paymentNote = null;
      this.isClientInvoiceDisplay = false;
      this.getPayments(true);
      this.mangoAPISrvc.notify('success', 'Success!', AppConstants.assignPayment);
      this.mangoAPISrvc.showLoader(false);
    }, err => {
      this.mangoAPISrvc.notify('error', 'Success!', AppConstants.errAssignPayment);
      this.mangoAPISrvc.showLoader(false);
    })
  }


  calculateData(item) {

    const invoiceBal = numeral(item.InvoiceBalance).value();
    const totalPayments = numeral(item.TotalPayments).value();
    const paymentsapplied = numeral(item.PaymentsApplied).value();
    const unappliedAmt = numeral(this.newTotalUnapplied).value();

    if (item.selected && (unappliedAmt) === 0) {
      this.mangoAPISrvc.notify('error', 'Error!', this.translate.instant('The Unapplied Balance is Zero. No Payment can be Applied.'));
      item.selected = false;
    }
    else {
      if (item.selected) {
        const computedBalace = (invoiceBal - unappliedAmt);
        if (computedBalace >= 0) {
          item.PaymentsApplied = unappliedAmt;
          item.PrevPaymentsApplied = unappliedAmt;
          item.InvoiceBalance = numeral((invoiceBal - unappliedAmt).toFixed(2)).format('0,0.00');
          item.TotalPayments = numeral((totalPayments + unappliedAmt).toFixed(2)).format('0,0.00');
          const valueStr = (unappliedAmt - numeral(item.PaymentsApplied).value());
          this.newTotalUnapplied = valueStr;
        } else {
          item.PaymentsApplied = invoiceBal;
          item.PrevPaymentsApplied = invoiceBal;
          item.InvoiceBalance = numeral().format('0,0.00');
          item.TotalPayments = numeral((totalPayments + invoiceBal).toFixed(2)).format('0,0.00');
          const valueStr = Math.abs(numeral(computedBalace).value());
          this.newTotalUnapplied = valueStr;
        }
      }
      else {
        item.PaymentsApplied = 0;
        item.PrevPaymentsApplied = 0;
        const valueStr = (unappliedAmt + paymentsapplied);
        item.InvoiceBalance = numeral((invoiceBal + paymentsapplied).toFixed(2)).format('0,0.00');
        item.TotalPayments = numeral((totalPayments - paymentsapplied).toFixed(2)).format('0,0.00');
        this.newTotalUnapplied = valueStr;
      }
    }

  }

  getPaymentSource(note) {
    let label = "-";
    if (note?.indexOf('(HP)') > 0) {
      label = "Hosted Page";
    } else if (note?.indexOf('(CTP)') > 0) {
      label = "Click to Pay";
    }
    else if (note?.indexOf('(CP)') > 0) {
      label = "Client Portal";
    }
    else if (note?.indexOf('(CR)') > 0) {
      label = "Cash Receipt";
    } else if (note?.indexOf('(MI)') > 0) {
      label = "Manual Invoice";
    }
    else if (note?.indexOf('(RP)') > 0) {
      label = "Recurring Payments";
    }
    return label;
  }

  getPayments(showPayments?) {
    const _this = this;
    _this.payments = [];
    _this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getPayments(this.clientID).subscribe(function (data: any) {
      data = data.map(function (obj) {
        obj['isExpandble'] = obj.paymentDetails.length > 0 ? true : false;
        obj['hasDebitMemo'] = obj.paymentDetails.filter((x) => { return x.InvoiceType == "Debit Memo" }).length;
        obj['hasRetainer'] = obj.paymentDetails.filter((x) => { return x.InvoiceType == "Retainer Payment" }).length;
        obj['paymentSource'] = _this.getPaymentSource(obj.PaymentNote);
        return obj;
      });

      _this.payments = [...data];

      // removing $ and , in invoiceNumber column
      _this.payments = data.map(function (obj) {
        // obj['displayDate'] =  moment(obj.PaymentDate.substr(0,10)).format("MM/DD/YYYY");
        for (let i = 0; i < obj.paymentDetails.length; ++i) {
          let unAppliedAmt = numeral(obj.paymentDetails[i]['PaymentUnapplied']).value();
          unAppliedAmt = unAppliedAmt ? unAppliedAmt : 0.00;
          obj.paymentDetails[i]['PaymentUnapplied'] = unAppliedAmt;
          obj.paymentDetails[i]['WriteOffAmount'] = obj.paymentDetails[i]['WriteOffAmount'] ? numeral(obj.paymentDetails[i]['WriteOffAmount']).value() : 0;
          console.log(obj.paymentDetails[i]['WriteOffAmount'])
          let invoiceNo = numeral(obj.paymentDetails[i]['InvoiceNumber']).value();
          invoiceNo = invoiceNo ? invoiceNo : "";
          obj.paymentDetails[i]['InvoiceNumber'] = invoiceNo;
          // obj.paymentDetails[i]['displayDate'] =  moment(obj.paymentDetails[i].InvoiceDate.substr(0,10)).format("MM/DD/YYYY");
        }
        return obj;
      });

      for (let i = 0; i < _this.payments.length; i++) {
        let appliedAmt = numeral(_this.payments[i].PaymentAmount).value();
        appliedAmt = appliedAmt ? appliedAmt : 0.00;
        _this.payments[i].PaymentAmount = appliedAmt;

        if (_this.payments[i].AppliedDeposit != false) {
          _this.payments[i].AppliedDeposit = true;
        }
        _this.totalPaymentAmount = _this.payments[i].PaymentAmount + _this.totalPaymentAmount;
        _this.tempTotalPaymentAmount = _this.totalPaymentAmount;

        let appliedUnapplied = numeral(_this.payments[i].PaymentUnapplied).value();
        appliedUnapplied = appliedUnapplied ? appliedUnapplied : 0.00;
        _this.payments[i].PaymentUnapplied = appliedUnapplied;

        _this.totalRetainerBalance = _this.totalRetainerBalance + _this.payments[i].PaymentUnapplied;
        _this.tempTotalRetainerBalance = _this.totalRetainerBalance;

        let writeOffAmt = numeral(_this.payments[i].WriteOffAmount).value();
        writeOffAmt = writeOffAmt ? writeOffAmt : 0.00;
        _this.payments[i].WriteOffAmount = writeOffAmt;

        _this.totalWriteOff = _this.payments[i].WriteOffAmount + _this.totalWriteOff;
        _this.tempTotalWriteOff = _this.totalWriteOff;
        _this.payments[i].paymentDetails.totalwa = 0;
        _this.payments[i].paymentDetails.total = 0;

        for (let j = 0; j < _this.payments[i].paymentDetails.length; j++) {
          const currentObj = _this.payments[i].paymentDetails[j];
          currentObj.InvoiceNumber = currentObj.InvoiceNumber;

          let appliedAmt = numeral(currentObj.AppliedAmount).value();
          appliedAmt = appliedAmt ? appliedAmt : 0.00;
          currentObj.AppliedAmount = appliedAmt;

          _this.payments[i].paymentDetails.total = _this.payments[i].paymentDetails.total + currentObj.AppliedAmount;

          let writeOffAmt = numeral(currentObj.WriteOffAmount).value();
          writeOffAmt = writeOffAmt ? writeOffAmt : 0.00;
          currentObj.WriteOffAmount = writeOffAmt;

          _this.payments[i].paymentDetails.totalwa = currentObj.WriteOffAmount + _this.payments[i].paymentDetails.totalwa;
        }
      }
      _this.loadFilterGrid();

      if (showPayments) {
        _this.selectedPayment = _this.payments.find(item => item['PaymentHeaderID'] == _this.selectedPayment['PaymentHeaderID'])
        if (_this.selectedPayment && _this.selectedPayment['paymentDetails'].length > 0) {
          _this.openShowPayments(_this.selectedPayment)
        } else {
          _this.sidePanelDisplay = false;
        }
      }
      _this.mangoAPISrvc.showLoader(false);
      //_this.getAllCounts(_this.clientID);
    }, error => {
      _this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  previewInvoice(event: any, obj) {
    if (obj.PaymentType != 'Credit Memo') {
      Swal.fire({
        icon: 'error',
        title: 'Information!',
        text: 'Only Credit Memos can be printed',
        showConfirmButton: false,
        timer: 3500
      })
    } else {
      let url;
      url = environment.SERVICE_ADDRESS + "/api/pentaho-api-repos/%3Ahome%3Atim%3ACredit Memo.prpt/report?ClientID=" + this.clientID + "&PaymentHeaderID=" + obj.PaymentHeaderID;
      const newWindow = this.nativeWindow.open(decodeURI(url));
      newWindow.location = url;
    }
  }

  reverseNSF(paymentHeaderObj) {
    Swal.fire({
      title: 'Reverse NSF',
      text: this.translate.instant('payments.reverse_nsf'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.reverseNSF(paymentHeaderObj).subscribe((result) => {
          this.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.updateMsg);
          this.mangoAPISrvc.showLoader(false);
          this.getPayments();
        }, error => {
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        })
      }
    })
  }

  preventImportedTransactions(data) {
    if(data?.isImported || data?.ImportedIndex) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: this.translate.instant("prevent_processing_imported_data"),
        showConfirmButton: true,
      });
      throw 'Imported data cannot be processed'
    }
  }

  /*Reverses Payment.  Need to check to see if PaymentHeader has been
    deposited.
    If AppliedDeposit = TRUE need to create PaymentHeader and PaymentDetail based on
    BillingHeaderID update BillingHeaderID.  billingHeaderID.InvoiceBalance += PaymentAmount

    If AppliedDeposit = FALSE then delete PaymentHeader and PaymentDetail update BillingHeaderID
    billingHeaderID.InvoiceBalance += PaymentAmount
  */
  reversePayment(rowData: any, isreversePayment: boolean, rowindex, isNsfReturn: boolean = false) {
    this.preventImportedTransactions(rowData);
    if (rowData["PaymentType"] === "ACH" && !rowData["NSFPaymentHeaderID"] && !isNsfReturn) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: this.translate.instant("payments.ach_reverse_payment"),
      });
      return;
    }

    const parent = this;
    const amountValue = numeral(rowData.PaymentAmount).value();

    if (rowData["NSFPaymentHeaderID"]) {
      parent.reverseNSF(rowData);
      return;
    }

    if (!isreversePayment && rowData["isNSFReturned"]) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: parent.translate.instant("payments.payment_returned_already"),
        showConfirmButton: true,
      });
      return;
    }

    if (isreversePayment && rowData["isNSFReturned"]) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: parent.translate.instant("payments.payment_is_nsf"),
        showConfirmButton: true,
      });
      return;
    }

    if (amountValue && amountValue < 0) {
      parent.mangoAPISrvc.notify("error", "Error!", "This payment can't be reversed.");
      return false;
    }

    if (rowData["PaymentType"] == "Credit Card") {
      Swal.fire({
        icon: "warning",
        title: "Delete Not Allowed!",
        text: parent.translate.instant("payments.ach_cc_delete"),
        showConfirmButton: true,
      });
      return;
    }

    const isDeposited =
      rowData["SelectedForDepositToBank"] &&
      rowData["PaymentType"] != "Write-Off" &&
      rowData["PaymentType"] != "Credit Memo";

    if (isDeposited || isNsfReturn) {
      //reversePayment that has already been deposited
      // if paymentType = Write-Off then delete only
      if (isreversePayment) {
        Swal.fire({
          title: "Delete Payment",
          text: "The selected payment has already been deposited.  This may affect your deposit amount made to the bank.  Do you want to continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          cancelButtonText: "No, keep it",
        }).then((result) => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.processReversePayment(rowData, rowindex);
            //parent.processPaymentActions(rowData, true);
          }
        });
        //handle a returned payment that has already been deposited
      } else {
        Swal.fire({
          title: "Process A Returned Item",
          text: "Would you like to continue with processing a returned Item?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, do it!",
          cancelButtonText: "No, keep it",
        }).then((result) => {
          if (result.value) {
            // call function to reverse payment
            parent.mangoAPISrvc.showLoader(true);
            parent.processPaymentActions(rowData, false, isDeposited);
          }
        });
      }
    } else {
      Swal.fire({
        title: "Delete Payment",
        text: "Would you like to continue to delete this payment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!", // call function to reverse payment
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          parent.processReversePayment(rowData, rowindex);
        }
      });
    }
  }

  processReverseHeaderAndDetails(rowData: any, deletePaymentToBank?: boolean) {
    this.mangoAPISrvc.reversePayment({ paymentObj: rowData, deletePaymentToBank }).subscribe((result: any) => {
      const logdata = {}
      logdata['Action'] = "Reverse Payment";
      logdata['Description'] = "Payment # " + rowData.PaymentHeaderID + ` --${this.clientName}`;
      logdata['Table'] = "";
      const isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if (!isManaging) {
        this.mangoAPISrvc.addUserLogs(logdata).subscribe((res) => {
          console.log(res);
        }, (err) => {
          console.log(err);
        });
      }

      this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);

      this.mangoAPISrvc.showLoader(false);
      this.getPayments();
    }, err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
    })
  }

  processReversePayment(rowData: any, rowindex: any) {
    const parent = this

    // Update the BillingHeaderID, the balance, Delete the Deposited, PaymentHeaderID and PaymentDetailID
    if (rowData['SelectedForDepositToBank'] == true && rowData['PaymentToBankID'] > 0) {
      parent.mangoAPISrvc
        .reverseDeposit(rowData.PaymentToBankID, {
          PaymentAmount: rowData.PaymentAmount,
        })
        .subscribe(
          (result: any) => {
            if (result?.toDelete)
              parent.processReverseHeaderAndDetails(rowData, true)
            else
              parent.processReverseHeaderAndDetails(rowData)
          },
          (err) => {
            console.log(err)
            parent.mangoAPISrvc.showLoader(false);
          }
        );
    } else {
      parent.processReverseHeaderAndDetails(rowData)
    }
  }

  processPaymentActions(rowData: any, isReverse: boolean, isDeposited) {
    const parent = this;
    const paymentHeaderObj = {};
    paymentHeaderObj['ClientID'] = this.clientID;
    paymentHeaderObj['PostedQB'] = paymentHeaderObj['QBReference'] = paymentHeaderObj['BankID'] = paymentHeaderObj['ProjectID'] = paymentHeaderObj['PaymentToBankID'] = paymentHeaderObj['PaymentToBankID'] = paymentHeaderObj['BillingHeaderID'] = null;

    paymentHeaderObj['PaymentDate'] = new Date( );

    paymentHeaderObj['CheckRef'] = rowData.CheckRef;
    paymentHeaderObj['PaymentType'] = rowData.PaymentType;
    paymentHeaderObj['PaymentToBankID'] = rowData.PaymentToBankID;
    paymentHeaderObj['PaymentAmount'] = numeral(rowData.PaymentAmount).value() * -1;
    paymentHeaderObj['AmountPreviouslyAppliedRetainer'] = paymentHeaderObj['AmountAppliedRetainer'] = paymentHeaderObj['PaymentUnapplied'] = paymentHeaderObj['WriteOff'] = paymentHeaderObj['WriteOffAmount'] = 0;

    paymentHeaderObj['PaymentNote'] = "Reverse Payment Dated: "+this.mangoUtils.formatDateString(rowData.PaymentDate)+ (isDeposited ? " (Deposited)" : '');
    paymentHeaderObj['SelectedForDepositToBank'] = paymentHeaderObj['AppliedDeposit'] = true;

    paymentHeaderObj['BillingPartnerID'] = this.selectedCustomer['BillingPartnerID'];
    paymentHeaderObj['GroupDescriptionID'] = this.selectedCustomer['GroupDescriptionID'];
    paymentHeaderObj['OriginatingPartnerID'] = this.selectedCustomer['OriginatingPartnerID'];
    paymentHeaderObj['ClientTypeID'] = this.selectedCustomer['ClientTypeID'];
    paymentHeaderObj['NSFtrue'] = false;
    paymentHeaderObj['NSFPaymentHeaderID'] = rowData["PaymentHeaderID"];
    paymentHeaderObj['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);

    if(!isReverse) {
      //for NSF only!
      this.mangoAPISrvc
        .updatePaymentHeader(
          {
            PaymentNote: `NSF Item ${rowData["PaymentNote"]}`,
            isNSFReturned: true,
          },
          rowData["PaymentHeaderID"]
        )
        .subscribe(
          () => {},
          (err) => {
            this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
            this.mangoAPISrvc.showLoader(false);
          }
        );
    }
    // creating the payment header
    this.mangoAPISrvc.createPaymentHeader(paymentHeaderObj).subscribe((paymentHeaderdata: any) => {

      for (let i = 0; i < rowData.paymentDetails.length; i++) {
        const rowPaymentDetails = rowData.paymentDetails[i];

        const paymentDetailObj = {};
        paymentDetailObj['PaymentHeaderID'] = paymentHeaderdata.data.PaymentHeaderID;
        paymentDetailObj['BillingHeaderID'] = rowPaymentDetails.BillingHeaderID;

        paymentDetailObj['PaymentDate'] = new Date( );

        paymentDetailObj['CheckRef'] = paymentHeaderObj['CheckRef'];
        paymentDetailObj['InvoiceNumber'] = rowPaymentDetails.InvoiceNumber;
        paymentDetailObj['AppliedAmount'] = numeral(rowPaymentDetails.AppliedAmount).value() * -1;
        paymentDetailObj['WriteOffAmount'] = 0;
        paymentDetailObj['PaymentType'] = paymentHeaderObj['PaymentType'];
        paymentDetailObj['InvoiceDate'] = rowPaymentDetails.InvoiceDate;
        paymentDetailObj['OldPaymentDetailID'] = null;
        paymentDetailObj['OldPaymentHeaderID'] = null;
        paymentDetailObj['OldBillingHeaderID'] = null;
        paymentDetailObj['ClientID'] = parent.clientID
        if (isReverse) {
          paymentDetailObj['PaymentNote'] = "Reversal - Deposit Made";
        } else {
          paymentDetailObj['PaymentNote'] = "Returned Item";
        }
        parent.mangoAPISrvc.createPaymentDetails(paymentDetailObj).subscribe(PaymentDetailsdata => {
          parent.mangoAPISrvc.getBillingHeaderRecordById(rowPaymentDetails.BillingHeaderID).subscribe(item => {
            // update Billing Header Record on BillingHeaderID
            const value1: any = (item['InvoiceBalance']) ? parseFloat(item['InvoiceBalance']) : 0;
            const value2: any = (item['TotalPayments']) ? parseFloat(item['TotalPayments']) : 0;
            const value3: any = parseFloat(paymentDetailObj['AppliedAmount']);
            item['InvoiceBalance'] = value1 - value3;
            item['TotalPayments'] = value2 + value3;
            item['LastModifiedStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
            parent.mangoAPISrvc.updateBillingHeader(item, item['BillingHeaderID']).subscribe(clientdata => {
              parent.mangoAPISrvc.notify('success', 'Deleted!', AppConstants.updateMsg);
              parent.mangoAPISrvc.showLoader(false);
              parent.getPayments();
            });
          });
        });
      }
    });//

  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject(AppConstants.Payments + '_' + AppConstants.SearchString, "");
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(AppConstants.Payments + '_' + AppConstants.SearchString, obj.filters.global.value);
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      this.dataTableComponent.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  openShowPayments(data) {
    this.paymentDetailsSidebar = data.paymentDetails;
    this.sidePanelDisplay = true;
    this.selectedPayment = data;
  }

  // onRowExpandEvent(event) {
  //   this.paymentDetailsSidebar = event.data.paymentDetails;
  //   this.sidePanelDisplay = true;
  //   this.selectedPayment = event.data;
  //   if (!event.data.isExpandble) {
  //     return false;
  //   }
  // }

  cancelShowPayments() {
    this.sidePanelDisplay = false;
  }

  onShowDebitMemoDialog(rowData) {
    this.preventImportedTransactions(rowData)
    this.selectedRowData = null;
    this.selectedRowData = rowData;
    this.showDebitMemoDialog = true;
  }

  onSaveDebitMemoDialog(data) {
    this.debitMemoDesc = data?.description;
    this.showDebitMemoDialog = false;
    this.convertToDebitMemo(this.selectedRowData)
    this.selectedRowData = null;
  }

  onCloseDebitMemoDialog(data) {
    this.showDebitMemoDialog = false;
  }

  onCloseApplyAdvanceDialog(data) {
    this.totalPaymentAmount = 0;
    this.totalRetainerBalance = 0;
    this.totalWriteOff = 0;

    this.getPayments();
  }

  convertToDebitMemo(rowData) {

    if (rowData.paymentDetails.length === 0) { // no retainer invoice process
      const billingHeaderObj = {
        InvoiceDate: new Date( ),

        InvoiceAmount: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
        TotalPayments: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
        TotalServices: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
        InvoiceBalance: "0.00",
        InvoiceType: "Debit Memo",
        DescriptionShort: this.debitMemoDesc,
        LastModifiedStaffID: this.encrDecSrvc.getObject(AppConstants.staffID)
      }

      const paymentHeaderObj = {
        RetainerHeaderID: null,
        PaymentUnapplied: "0.00",
        LastModifiedStaffID: this.encrDecSrvc.getObject(AppConstants.staffID)
      }

      const paymentDetailObj = {
        BillingHeaderID: rowData["BillingHeaderID"],
        PaymentHeaderID: rowData["PaymentHeaderID"],
        AppliedAmount: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
        PaymentType: rowData["PaymentType"],
        PaymentNote: rowData["PaymentNote"],
        CheckRef: "DM",
        InitialAmount: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
        ClientID: this.clientID,
        PaymentDate: rowData['PaymentDate'],

        InvoiceDate: new Date( )
      }

      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateBillingHeader(billingHeaderObj, rowData['BillingHeaderID']).subscribe((bhData) => {
        this.mangoAPISrvc.updatePaymentHeader(paymentHeaderObj, rowData['PaymentHeaderID']).subscribe((phData) => {
          this.mangoAPISrvc.createPaymentDetails(paymentDetailObj).subscribe((pdData) => {
            this.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
            this.mangoAPISrvc.showLoader(false);
            this.getPayments();
          }, err => {
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            this.mangoAPISrvc.showLoader(false);
          })
        }, err => {
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        })
      }, err => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      })
    } else {

      const parent = this;
      const newHeader = {};
      newHeader['ClientID'] = parent.clientID;
      newHeader['InvoiceDate'] = rowData['PaymentDate'];
      newHeader['DescriptionShort'] = parent.debitMemoDesc;
      newHeader['InvoiceAmount'] = rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"];
      newHeader['TotalPayments'] = rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"];
      newHeader['TotalServices'] = rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"];
      newHeader['InvoiceBalance'] = 0;
      newHeader['PaymentsApplied'] = 0;
      newHeader['TotalStaffCost'] = 0;
      newHeader['InvoicePosted'] = true;
      newHeader['InvoiceType'] = 'Debit Memo';
      newHeader['NSFtrue'] = false;
      newHeader['CreatedbyStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);

      const paymentHeaderObj = {
        RetainerHeaderID: null,
        PaymentUnapplied: "0.00",
        LastModifiedStaffID: parent.encrDecSrvc.getObject(AppConstants.staffID)
      }

      if(rowData['BillingHeaderID']) {
        parent.mangoAPISrvc.getBillingHeaderRecordById(rowData.BillingHeaderID).subscribe(
          (item) => {
            if (item["InvoiceType"] == "Retainer") {
              parent.mangoAPISrvc.deleteBillingHeader(rowData.BillingHeaderID).subscribe((response) => {
                parent.mangoAPISrvc.showLoader(false);
              }, err => {
                parent.mangoAPISrvc.showLoader(false);
              });
            }
          },
          (err) => {
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      } else {
        for (let i = 0; i < rowData.paymentDetails.length; i++) {
          const item = rowData.paymentDetails[i];
          if (item.InvoiceType === "Retainer Payment" || item.InvoiceType == "Retainer") {
            parent.mangoAPISrvc.deletePaymentDetails(item.PaymentDetailID).subscribe((response) => {
              parent.mangoAPISrvc.deleteBillingHeader(item.BillingHeaderID).subscribe((response) => {
                parent.mangoAPISrvc.showLoader(false);
              }, err => {
                parent.mangoAPISrvc.showLoader(false);
              });
              parent.mangoAPISrvc.showLoader(false);
            }, err => {
              parent.mangoAPISrvc.showLoader(false);
            });
          }

        }
      }

      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createBillingHeader(newHeader).subscribe((bhData: any) => { //create new billingHeader to the retainer
        const paymentDetailObj = {
          BillingHeaderID: bhData.data["BillingHeaderID"],
          PaymentHeaderID: rowData["PaymentHeaderID"],
          AppliedAmount: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
          PaymentType: rowData["PaymentType"],
          PaymentNote: rowData["PaymentNote"],
          CheckRef: "DM",
          InitialAmount: rowData["PaymentUnapplied"] ? Math.abs(numeral(rowData["PaymentUnapplied"]).value()) : rowData["PaymentUnapplied"],
          ClientID: parent.clientID,
          PaymentDate: rowData['PaymentDate'],

          InvoiceDate: new Date( )
        }
        parent.mangoAPISrvc.showLoader(true);
        parent.mangoAPISrvc.updatePaymentHeader(paymentHeaderObj, rowData['PaymentHeaderID']).subscribe((phData) => {
          parent.mangoAPISrvc.createPaymentDetails(paymentDetailObj).subscribe((pdData) => {
            parent.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
            parent.mangoAPISrvc.showLoader(false);
            parent.getPayments();
          }, err => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          })
        }, err => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        })
      }, err => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      })

    }

  }

  transferAdvancePayment(data) {
    this.preventImportedTransactions(data)
    if (data?.paymentDetails?.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: this.translate.instant('client.transfer_advance_payment_header_warning'),
        showConfirmButton: true,
      })
      return
    }


    this.newTotalUnapplied = data.PaymentUnapplied;
    this.selectedOldBillingHeaderID = data.BillingHeaderID;
    this.selectedOldPaymentHeaderID = data.PaymentHeaderID;
    this.selectedAppliedAmount = data.PaymentUnapplied;
    this.isTransferAdvance = true
    this.isClientInvoiceDisplay = true;
  }

  cancelTransferPayment() {
    this.newTotalUnapplied = null;
    this.selectedOldBillingHeaderID = null;
    this.selectedOldPaymentHeaderID = null;
    this.selectedOldPaymentDetailID = null;
    this.selectedAppliedAmount = 0;
    this.paymentNote = null;
    this.isClientInvoiceDisplay = false;
    this.selClient = {}
    this.isTransferAdvance = false;
  }
}