import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
("@angular/core");

import { SharedComponentsService } from "@app/shared/components";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { timer } from 'rxjs';
import moment from "moment";
import { environment } from '@environments/environment';
@Component({
  selector: "app-to-do",
  templateUrl: "./to-do.component.html",
})
export class ToDoComponent implements OnInit {
  public priorityOptions: any = [
    { label: "Select Priority", value: "" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  @Input() displayTaskModal: boolean = false;
  @Output() closeTaskModal = new EventEmitter<any>();
  @ViewChild("todoForm") taskMainForm;

  public clientListDatasource: any = [];
  public filteredClients: any[];
  public selClient: any = null;
  public IsEnableHistory: boolean = true;
  public IsEnableSaveNew: boolean = true;
  public taskObj: any = {};
  public noteCategories: any = [];
  public defaultTaskRecord: any = {
    DateFinished: null,
    DateCreated: null,
    ClientID: null,
    StaffID: null,
    Description: null,
    DueDate: null,
    Status: false,
    Priority: "Low",
    NoteCategoryID: null,
    ClientName: null,
    NoteCategory: null,
  };
  isFormValid: boolean = false;
  isEditFlow: boolean = false;
  intervalid: any;
  minDueDate = new Date();
  dateCreated = new Date();
  staffListOptions: any = [];
  private taskMainFormSubscription;
  public companyDetails = {};
  public loginCompanyId: any;

  constructor(
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    public sharedSrvc: SharedComponentsService,
    public mangoUtils: mangoUtils
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);

  }

  ngOnInit(): void {
    this.loadCompanySettings();
    this.defaultTaskRecord.DateCreated = this.dateCreated;
    //this.defaultTaskRecord.DueDate = this.dateCreated;
    this.taskObj = this.defaultTaskRecord;
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  ngAfterViewInit(): void {
    const self = this;
    self.taskMainFormSubscription = self.taskMainForm.control.valueChanges.subscribe((values) => {
      self.isFormValid = self.taskMainForm.valid && self.taskMainForm.dirty;
    });
  }

  ngOnDestroy() {
    this.taskMainFormSubscription.unsubscribe();
  }

  loadCompanySettings() {
    // this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyMangoDetails(this.loginCompanyId).subscribe(data => {
      // this.mangoAPISrvc.showLoader(false);
      this.companyDetails = data[0];
    }, err => {
      this.mangoAPISrvc.showLoader(false);
    })
  }

  onDateFinishedChange() {
    this.taskObj.Status = true;
    setTimeout(() => {
      this.isFormValid = true;
    }, 500)
  }

  onChangeStatus() {
    if (this.taskObj.Status) {
      this.taskObj.DateFinished = this.taskObj.DateFinished ? new Date(this.taskObj.DateFinished) : new Date();
    }
    else {
      this.taskObj.DateFinished = null;
    }
  }

  openTaskModal(data) {
    this.minDueDate = new Date();
    this.dateCreated = new Date();
    const parent = this;
    parent.defaultTaskRecord = data;
    //@ ravi : 18/May
    //parent.defaultTaskRecord.DueDate = data.isEditFlow && data.DueDate ? this.mangoUtils.removeOffset(data.DueDate) : null;
    parent.defaultTaskRecord.DueDate = data.isEditFlow && data.DueDate ? moment(data.DueDate).startOf('day').toDate() : null;
    parent.taskObj = parent.defaultTaskRecord;
    parent.isEditFlow = data["isEditFlow"];
    parent.getAllStaffList();
    parent.getCategories();
    parent.defaultTaskRecord.DateFinished = parent.defaultTaskRecord.DateFinished && parent.defaultTaskRecord.Status == "Complete" ?
      new Date(parent.defaultTaskRecord.DateFinished) : null;
    parent.selClient = null;
    if (data.ClientID) {
      parent.selClient = {
        ClientID: data.ClientID,
        ClientName: data.ClientName,
      };
    }
    delete data["isEditFlow"];
    setTimeout(() => {
      if (parent.isEditFlow) {
        parent.defaultTaskRecord.DateCreated = new Date(data.DateCreated)
        parent.defaultTaskRecord.Status =
          parent.defaultTaskRecord.Status == "Complete" ? true : false;
        parent.selClient = {
          ClientID: data.ClientID,
          ClientName: data.ClientName,
        };
      } else {
        parent.defaultTaskRecord.StaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
        parent.defaultTaskRecord.CreatedByStaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
        if (parent.defaultTaskRecord.CreatedByStaffID) {
          const temp = parent.staffListOptions.find(item => item.value == parent.defaultTaskRecord.CreatedByStaffID)
          parent.defaultTaskRecord.CreatedByStaffName = temp['label'];
        }
        parent.defaultTaskRecord.DateCreated = this.dateCreated;
        parent.defaultTaskRecord.Priority = "Low";
      }
      /*  parent.defaultTaskRecord.DueDate = parent.defaultTaskRecord.DueDate ? new Date(
         parent.defaultTaskRecord.DueDate
       ) : null ; */
      parent.displayTaskModal = true;
      parent.taskObj = parent.defaultTaskRecord;
    }, 1000);
  }

  saveTodo(type) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    if (parent.taskObj.Status) {
      parent.taskObj.DateFinished = parent.taskObj.DateFinished ? parent.taskObj.DateFinished : new Date();
    }

    parent.taskObj.Status = parent.taskObj.Status == true ? "Complete" : "Open";
    parent.taskObj.ClientName = parent.selClient?.ClientName;
    parent.taskObj.DueDate = moment(parent.taskObj.DueDate).startOf('day').toDate();
    parent.taskObj.NoteCategory = parent.noteCategories.filter(
      (item) => item.value == parent.taskObj.NoteCategoryID
    )[0]["label"];
    // if(parent.taskObj.DateFinished){
    //   parent.taskObj.DateFinished = this.mangoUtils.removeOffset(parent.taskObj.DateFinished);
    // }
    if (!parent.isEditFlow) {
      parent.mangoAPISrvc.addNewTodo(parent.taskObj).subscribe(
        (data) => {
          if (parent.companyDetails["isToDoEmailNotify"]) parent.sendMailtoUser(parent.taskObj);
          if (type == "close") {
            parent.closeTaskFormModal(true, true, type);
          } else if (type == "new" || type == "duplicate") {
            parent.closeTaskFormModal(true, false, type);
          }
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify(
            "success",
            "Success!",
            AppConstants.createMsg
          );
        },
        (error) => {
          parent.mangoAPISrvc.notify(
            "error",
            "Error!",
            AppConstants.createErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.mangoAPISrvc
        .updateTodo(parent.taskObj.ToDoID, parent.taskObj)
        .subscribe(
          (data) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.closeTaskFormModal(true, true, type);
            parent.mangoAPISrvc.notify(
              "success",
              "Success!",
              AppConstants.updateMsg
            );
          },
          (error) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify(
              "error",
              "Error!",
              AppConstants.updateErrorMsg
            );
          }
        );
    }
  }

  closeTaskFormModal(isRefresh, isClose, type) {
    if (isRefresh) {
      this.closeTaskModal.emit(this.defaultTaskRecord);
      this.sharedSrvc.sharedCompVisibility.toDo = true;
      this.sharedSrvc.setToDoDialogData(this.defaultTaskRecord);
    }

    if (isClose) {
      this.taskMainForm.reset();
      this.taskObj = {};
      this.displayTaskModal = false;
    }
    else {
      if (type == "new") {
        this.taskMainForm.reset();
        this.selClient = null;

        setTimeout(() => {
          this.taskObj = this.defaultTaskRecord;
          this.taskObj.DueDate = this.dateCreated;
          this.taskObj.DateCreated = this.dateCreated;
          this.taskObj.Status = false;
          this.taskObj.StaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
          this.taskObj.CreatedByStaffID = this.encrDecSrvc.getObject(AppConstants.staffID);
        }, 100)
        this.displayTaskModal = true;
        this.sharedSrvc.sharedCompVisibility.toDo = true;
      }
      else if (type == "duplicate") {
        this.displayTaskModal = true;
        this.sharedSrvc.sharedCompVisibility.toDo = true;
      }
    }


  }

  openTodoDialog(data) {
    this.displayTaskModal = true;
    this.openTaskModal(data);
  }

  fetchClients() {
    if (this.clientListDatasource.length == 0) {
      const list = this.encrDecSrvc.clientList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientListDatasource.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        const client = this.clientListDatasource[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  /*
  dropDownselect
  */
  handleSelectClick(event) {
    this.selClient = event;
    this.taskObj.ClientID = event.ClientID;
  }

  getCategories() {
    const _this = this;
    _this.noteCategories = [];
    const tempList = this.encrDecSrvc.getObject(AppConstants.noteCategories);
    for (let i = 0; i < tempList.length; i++) {
      _this.noteCategories.push({
        label: tempList[i].NoteCategory,
        value: tempList[i].NoteCategoryID,
      });
    }
  }

  getAllStaffList() {
    const parent = this;

    const item = parent.encrDecSrvc.getObject(AppConstants.todoStaffList);
    if (item?.length > 0) {
      parent.staffListOptions = item;
    }
    else {
      parent.staffListOptions = [];
      parent.mangoAPISrvc.getAllTodoStaff().subscribe((result: any) => {
        const item = result;
        for (let i = 0; i < item.length; ++i) {
          if (item[i].Inactive)
            continue;
          parent.staffListOptions.push({
            label: item[i].StaffName,
            value: item[i].StaffID,
            Email: item[i].Email,
            isNotifyTodo: item[i].isNotifyTodo
          });
        }

        this.encrDecSrvc.addObject(AppConstants.todoStaffList, parent.staffListOptions);
      });
    }



  }

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }

    this.defaultTaskRecord.Description = valueArr.join(" ");
  }


  sendMailtoUser(details: any) {
    const parent = this;
    const toArr = [];
    const companysendInBlueObj = {
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: "", name: "" }],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 55,
      params: {},
    };
    const staff = parent.staffListOptions.filter(itm => itm.value === details.StaffID);
    if(staff && staff[0]?.isNotifyTodo === false) {
      return;
    }
    const currStaffLog = this.encrDecSrvc.getObject(AppConstants.staffID);
    const currStaffDetails = parent.staffListOptions.filter(itm => itm.value === currStaffLog);
    if (currStaffLog !== staff[0].value) {
      const toObj = { email: '', name: '' };
      toObj['email'] = staff[0].Email;
      toObj['name'] = staff[0].label;
      toArr.push(toObj)
      companysendInBlueObj.to = toArr;
      companysendInBlueObj.params["assignedStaff"] = staff.length > 0 ? staff[0].label : "";
      companysendInBlueObj.params["createdStaff"] = currStaffDetails.length > 0 ? currStaffDetails[0].label : "";
      companysendInBlueObj.params["Client"] = details.ClientName;
      companysendInBlueObj.params["Priority"] = details.Priority;
      companysendInBlueObj.params["Description"] = details.Description;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
        (data) => {
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
        },
        (err) => {
          // parent.mangoAPISrvc.showLoader(false);
        })
    }
    else {
      console.log("Dont send email...");
    }

  }
}
