import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MangoApiService, EncrDecrService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import Swal from 'sweetalert2';
import { environment } from '@environments/environment';
declare let $: any;
declare let FattJs: any;
declare let numeral: any;


@Component({
  selector: 'app-app-payment',
  templateUrl: './app-payment.component.html',
  styleUrls: ['./app-payment.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppPaymentComponent implements OnInit {
  @ViewChild("firstNameInputFocus") firstNameInputField: ElementRef;
  public isInvalidToken = false;
  public isApiLoaded = false;
  public nativeWindow: any;
  public companyData = null;
  public imageUrl: any = null;
  public modeOfPayment;
  public cardOptionsList: any = [];
  public allEmailContacts: any = [];
  public paymentProfileData: any = {};
  public isSelctedPaymentIndex = -1;
  public cardProfile = null;
  public selectedModeOfPayment;
  public isBusiness: string = 'false';
  public fattJs: any = {};
  public isSTaxEnabled: boolean = false;
  public isFullPayment: string = 'true';
  public transactionData: any = null;
  public myPaymentForm: UntypedFormGroup;
  public isStaxNewCard: boolean = false;
  public isProcessing: boolean = false;
  public isCrCard: boolean = false;
  public isDialogFormValid: boolean = false;
  public transCodeTypes: any = [];
  public isPaymentCompleted: boolean = false;
  public paidAmount: any = 0;
  public token: any = 0;
  public isMobileResolution: boolean;
  public currentInvoiceBalance: any = null;
  public AllUsers: any = [];
  public isDebitCard: boolean = false;
  cashReceiptEmailTemplate = null;
  public isStatementFlow: boolean = false;
  public paymentsApplied = []
  SurChargeAmount: number;
  public processData = { "Amount": null, "ClientID": null, "CompanyID": null, "PaymentType": null, "PaymentNote": null, "BillingPartnerID": null, "OriginatingPartnerID": null, "StaffAssignedID": null, "GroupDescriptionIDArray": null, "GroupDescriptionID": null, "ClientTypeID": null, "BillingHeaderID": null, "InvoiceType": null, "invoices": [] };

  constructor(public mangoUtils: mangoUtils, private _fb: UntypedFormBuilder, public translate: TranslateService, private _router: Router, private activatedRoute: ActivatedRoute, private mangoAPISrvc: MangoApiService, private encrDecSrvc: EncrDecrService) {
    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();
    this.mangoAPISrvc.notifyLogging(false);
    this.transCodeTypes = [{ label: "None", value: null }, { label: 'Checking', value: 27 }, { label: 'Savings', value: 37 }];
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  onResize(event) {
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit(): void {
    const parent = this;
    this.activatedRoute.params.subscribe(params => {
      parent.token = params.token;
      this.fetchDataFromToken(params.token);
      if (params.token.includes('stmt_')) {
        parent.isStatementFlow = true;
      }
    });
    this.initializeForm();
  }


  async fetchUpdatedDataFromToken(token) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc.getPaymentDetailsByToken(token).subscribe((data: any) => {
          if (data.company) {
            self.mangoAPISrvc.showLoader(false);
            resolve(numeral(data.company.InvoiceBalance).value());
          } else {
            self.mangoAPISrvc.showLoader(false);
            self.isApiLoaded = true;
            resolve(0);
          }
          self.cashReceiptEmailTemplate = data?.emailTemplate;
        }, (err) => {
          self.mangoAPISrvc.showLoader(false);
          reject(err);
        });
      } catch (error) {
        self.mangoAPISrvc.showLoader(false);
        reject(error);
      }
    });
  }

  public requiredcardFields = [
    "expDate",
    "FirstName",
    "LastName"
  ];

  public requiredACHFields = [
    "RoutingNumber",
    "AccountNumber",
    "TransCode",
    "FirstName",
    "LastName"
  ];

  public allrequiredFields = [
    "RoutingNumber",
    "AccountNumber",
    "expDate",
    "TransCode",
    "FirstName",
    "LastName"
  ];

  getEmailcontactsList(clientId) {
    const parent = this;
    parent.allEmailContacts = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailcontactsByClientID(clientId).subscribe((response: any) => {
      parent.allEmailContacts = response;
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  addCardValidators(requiredcardFields) {
    for (let i = 0; i < this.allrequiredFields.length; i++) {
      const fieldName = this.allrequiredFields[i];
      if (requiredcardFields.indexOf(fieldName) > -1) {
        this.myPaymentForm.controls[fieldName].setValidators([Validators.required]);
      } else {
        this.myPaymentForm.controls[fieldName].setValidators([]);
      }
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  initializeForm() {
    const parent = this;
    parent.myPaymentForm = parent._fb.group({
      CompanyID: [''],
      BankAcctNo: [''],
      CardNo: [''],
      expDate: [''],
      NameOnCard: [''],
      CardType: [''],
      securityCode: [''],
      RoutingNumber: [''],
      AccountNumber: [''],
      TransCode: [null],
      phone: [''],
      Amount: ['', [<any>Validators.required]],
      FirstName: [''],
      LastName: [''],
      EmailAddress: ['', [<any>Validators.required]],
      Address1: ['', [<any>Validators.required]],
      Address2: [''],
      City: ['', [<any>Validators.required]],
      State: ['', [<any>Validators.required]],
      Zip: ['', [<any>Validators.required]],
      Country: ['US'],
      SurChargeAmount: [0],
      withSurcharge: [0],
      isBusiness: [false]
    });

    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
      this.isValidStaxForm();
    })
  }

  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach(key => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && (this.cardProfile || this.isStaxNewCard)) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  fetchDataFromToken(token) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getPaymentDetailsByToken(token).subscribe((data: any) => {
      self.companyData = data;
      self.companyData.invoices = self.mangoUtils.sortByFiled(data.invoices, 'InvoiceDate');
      self.isInvalidToken = (data.company) ? true : false;
      self.imageUrl = (data.company) ? data.company.CompanyLogoWeb : null;
      self.isStaxNewCard = (data.cards.length == 0) ? true : false;
      if(data?.payments?.length > 0) {
        self.paymentsApplied = data.payments
      }
      if (data.company) {
        data.company.InvoiceBalance = numeral(data.company.InvoiceBalance).value() ? data.company.InvoiceBalance : 0;
        self.mangoAPISrvc.showLoader(false);
        self.getCustomerPortalSettings();
        self.checkStaxEnable();
        self.getAllStaff();
        self.getEmailcontactsList(data.company.ClientID);
        if (!self.companyData.company?.isAllowPartialPayments) {
          self.applyFullAmount()
        }
      } else {
        self.mangoAPISrvc.showLoader(false);
        self.isApiLoaded = true;
      }
    }, (err) => {
      self.imageUrl = null;
      self.isApiLoaded = true;
      self.isInvalidToken = false;
      self.mangoAPISrvc.showLoader(false);
    });
  }

  closeWindow() {
    this.nativeWindow.close();
  }

  getCustomerPortalSettings() {
    const self = this;
    self.isSelctedPaymentIndex = -1;
    self.isCrCard = false;
    if (self.companyData.company && self.companyData.company.modeOfPayment == 4) {
      self.modeOfPayment = self.companyData.company.firm_modeofpayment != null ? self.companyData.company.firm_modeofpayment : 3;
    } else {
      self.modeOfPayment = self.companyData.company.modeOfPayment != null ? self.companyData.company.modeOfPayment : 3;
    }
    //self.selectedModeOfPayment = (self.modeOfPayment == 2) ? 0 : self.modeOfPayment;
    if (self.modeOfPayment == 0) {
      self.isSelctedPaymentIndex = 0;
      self.isCrCard = true;
      const card = self.companyData.cards.filter((item) => item.TransType == 'CC');
      self.cardProfile = card.length > 0 ? card[0] : null
      self.cardOptionsList.push({ label: 'Credit Card', value: 0 });
      self.cardOptionsList.push({ label: 'Debit Card', value: 2 });
      self.genarateIFrames();
    } else if (self.modeOfPayment == 1) {
      self.isSelctedPaymentIndex = 1;
      const card = self.companyData.cards.filter((item) => item.TransType == 'ACH');
      self.cardProfile = card.length > 0 ? card[0] : null
      self.cardOptionsList.push({ label: 'ACH', value: 1 });
      self.fattJs = new FattJs(self.companyData.company.spkuid, {});
    }
    else if (self.modeOfPayment == 2) {
      self.cardOptionsList.push({ label: 'Select Payment Type', value: -1 });
      self.cardOptionsList.push({ label: 'Credit Card', value: 0 });
      self.cardOptionsList.push({ label: 'ACH', value: 1 });
      self.cardOptionsList.push({ label: 'Debit Card', value: 2 });
      self.fattJs = new FattJs(self.companyData.company.spkuid, {});
    } else {
      self.cardOptionsList = [];
    }
  }

  getAllStaff() {
    this.mangoAPISrvc.getMobilePaymentsPMAllStaffList(this.companyData.company.CompanyID).subscribe(
      (allStaff: any) => {
        for (let i = 0; i < allStaff.length; i++) {
          if (allStaff[i].Inactive)
            continue;
          const obj = {};
          obj["StaffID"] = allStaff[i]["StaffID"];
          obj["StaffName"] = allStaff[i]["StaffName"];
          obj["StaffNumber"] = allStaff[i]["StaffNumber"];
          obj["Email"] = allStaff[i]["Email"];
          this.AllUsers.push(obj);
        }
      });
  }

  // getStaxToken(result) {
  //   let cardObj = {};
  //   let parent = this;
  //   let formData = null;
  //   var extraDetails = null;
  //   parent.myPaymentForm.controls['isBusiness'].setValue(parent.isBusiness);
  //   formData = parent.myPaymentForm.value;
  //   parent.transactionData = result;
  //   if (this.isCrCard) {
  //     extraDetails = {
  //       total: numeral(formData.Amount).value(),
  //       firstname: formData.FirstName,
  //       lastname: formData.LastName,
  //       email: formData.EmailAddress,
  //       month: parseInt(formData.expDate.split('/')[0]),
  //       year: parseInt(formData.expDate.split('/')[1]),
  //       phone: formData.phone,
  //       address_1: formData.Address1,
  //       address_2: formData.Address2 ? formData.Address2 : "",
  //       address_city: formData.City,
  //       address_state: formData.State,
  //       address_zip: formData.Zip,
  //       method: 'card',
  //       validate: false,
  //       send_receipt: false,
  //     };
  //   } else {
  //     extraDetails = {
  //       total: numeral(formData.Amount).value(),
  //       firstname: formData.FirstName,
  //       lastname: formData.LastName,
  //       person_name: formData.FirstName + ' ' + formData.LastName,
  //       phone: formData.phone,
  //       address_1: formData.Address1,
  //       address_2: formData.Address2 ? formData.Address2 : "",
  //       address_city: formData.City,
  //       address_state: formData.State,
  //       address_zip: formData.Zip,
  //       bank_account: formData.AccountNumber,
  //       bank_routing: formData.RoutingNumber,
  //       bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
  //       bank_holder_type: (formData.isBusiness == 'true') ? "business" : "personal",
  //       method: 'bank',
  //       validate: false,
  //       send_receipt: false,
  //       url: "https://omni.fattmerchant.com/#/bill/",
  //     };
  //   }

  //   this.fattJs.tokenize(extraDetails).then((result) => {
  //     if (result) {
  //       // saving into Mango DB
  //       cardObj['CompanyID'] = parent.companyData.company.CompanyID;
  //       cardObj['ClientID'] = parent.companyData.company.ClientID;
  //       cardObj['CardNo'] = result.card_last_four;
  //       cardObj['ExpiryDate'] = result.card_exp;
  //       cardObj['NameOnCard'] = result.person_name;
  //       cardObj['TransType'] = parent.isCrCard ? 'CC' : 'ACH';
  //       cardObj['FirstName'] = formData.FirstName;
  //       cardObj['LastName'] = formData.LastName;
  //       cardObj['uid'] = result.id;
  //       cardObj['scid'] = result.customer_id;
  //       cardObj['CardType'] = parent.isCrCard ? result['card_type'] : result['bank_type'];
  //       if (!parent.isCrCard) {
  //         cardObj['BankAcctNo'] = formData['AccountNumber'].substr(formData['AccountNumber'].length - 4, formData['AccountNumber'].length);
  //         cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
  //       }
  //       cardObj['uid'] = parent.transactionData ? parent.transactionData.receiptID : null;
  //       if (!parent.cardProfile) {
  //         parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe((resultRes: any) => {
  //           parent.cardProfile = cardObj;
  //           parent.cardProfile['CustomerCardID'] = resultRes.id;
  //           parent.updateMango();
  //         },
  //           (data: any) => { parent.mangoAPISrvc.showLoader(false) }
  //         );
  //       } else {
  //         parent.mangoAPISrvc.updateCreditCardDetails(parent.cardProfile.CustomerCardID, cardObj).subscribe((resultRes: any) => {
  //           parent.cardProfile = cardObj;
  //           parent.updateMango();
  //         },
  //           (data: any) => { parent.mangoAPISrvc.showLoader(false) }
  //         );
  //       }

  //     }
  //   })
  //     .catch(err => {
  //       parent.mangoAPISrvc.showLoader(false)
  //       let msg = err['message'] ? err['message'] : null;
  //       if (!msg) {
  //         if (err.payment_attempt_message) {
  //           msg = err.payment_attempt_message;
  //         } else if (err.child_transactions && err.child_transactions.length > 0) {
  //           msg = err.child_transactions[0].message;
  //         } else {
  //           try {
  //             msg = typeof err === 'object' ? Object.keys(err).map((k) => err[k].join(' ')).join(' ') : JSON.stringify(err);
  //           } catch (error) {
  //           }
  //         }
  //       }
  //       Swal.fire({
  //         icon: "error",
  //         title: parent.translate.instant("Transaction Not Approved"),
  //         text: msg,
  //         showConfirmButton: false,
  //         timer: 7000,
  //       });
  //     });
  // }

  sendMailtoCompany(amt, toArr) {
    const parent = this;
    const companysendInBlueObj = {
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: "", name: "" }],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 17,
      params: {}
    };
    amt = "$" + numeral(amt).format("0,0.00");

    companysendInBlueObj.to = toArr;
    companysendInBlueObj.params["COMPANYNAME"] = parent.companyData.company.ClientName;
    companysendInBlueObj.params["PAYMENT_TYPE"] = parent.isCrCard ? 'Credit Card' : 'ACH';
    companysendInBlueObj.params["PAYMENTAMOUNT"] = amt;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      (data) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      (err) => {
        parent.mangoAPISrvc.showLoader(false);
      })

  }

  sendMailtoCustomers(amt, paymentHeaderId) {
    const parent = this;
    const sendInBlueObj = {};
    let toObj = { email: '', name: '' };
    var toArr = [];
    const senderObj = { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER }
    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['replyTo'] = { email: environment.EMAIL_RETURN_SENDER };

    const clientContacts = this.allEmailContacts
      .filter((item) => item.isEmailPaymentNotification);
    for (let index = 0; index < clientContacts.length; index++) {
      const element = clientContacts[index];
      toObj = { email: '', name: '' };
      toObj['name'] = element['ContactName'] ? element['ContactName'] : element['Email'];
      toObj['email'] = element['Email'];
      toArr.push(toObj);
    }

    sendInBlueObj['to'] = parent.mangoUtils.deduplicateEmail(toArr);
    const tags = [];

    var toArr = [];
    tags.push(parent.companyData.company['ClientID'].toString());
    tags.push('none');
    tags.push(paymentHeaderId.toString());
    tags.push(parent.companyData.company.CompanyID.toString());
    tags.push(parent.companyData.company['ClientName']);

    sendInBlueObj['tags'] = tags;
    amt = "$" + numeral(amt).format("0,0.00");

    if (sendInBlueObj['to'].length > 0) {
      if (!parent.cashReceiptEmailTemplate['EmailTemplateID']) {
        parent.cashReceiptEmailTemplate = parent.mangoUtils.getDefaultTemplate('cashReceipt', parent.companyData.company.CompanyID); // default
      }
      let htmlContent = parent.mangoUtils.getCashReceiptEmailTemplate();
      sendInBlueObj['subject'] = parent.cashReceiptEmailTemplate['EmailSubject'];

      const formData = parent.myPaymentForm.value;
      if (parent.companyData.company.isSurchargeEnabled && formData.SurChargeAmount > 0) {
        this.cashReceiptEmailTemplate['htmlBody'] += `</br></br><div style="text-align: center;font-size: 17px;color: #cb0000;">Transaction subject to a surcharge in the amount of <strong>$${formData.SurChargeAmount}</strong>.</div>`;
      }
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%InjectTemplate%", parent.cashReceiptEmailTemplate['htmlBody'])
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%ContactName%", parent.companyData.company['ContactPerson'])
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%YourFirmName%", parent.companyData.company.CompanyName)
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%ClientName%", parent.companyData.company['ClientName'])
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%Amount%", amt)
      htmlContent = this.mangoUtils.replaceAll(htmlContent, "%Email%", parent.companyData.company['Email'])
      sendInBlueObj['htmlContent'] = htmlContent;

      sendInBlueObj['subject'] = parent.mangoUtils.replaceAll(sendInBlueObj['subject'], "%ClientName%", parent.companyData.company['ClientName']);

      this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe((data) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      });
    }
  }

  async sendEmailToFirmAndCustomer(amt, paymentHeaderId) {
    const parent = this;
    if (parent.companyData.company.PortalPaymentNotifications?.length > 0) {
      parent.mangoAPISrvc.getUserInfoByCompanyID(parent.companyData.company['CompanyID']).subscribe((response: any) => {
        if (response?.length > 0) {
          const toArr = []
          response.forEach((staff) => {
            if (parent.companyData.company['PortalPaymentNotifications'].includes((staff.StaffID).toString())) {
              if (staff.Email) {
                toArr.push({ email: staff.Email, name: staff.StaffName })
              }
            }
          })
          parent.sendMailtoCompany(amt, toArr);
        }
      })
    }
    if (parent.allEmailContacts.length > 0 && parent.companyData.company['EmailPaymentPortal'] == true) {
      parent.sendMailtoCustomers(amt, paymentHeaderId);
    }
    parent.mangoAPISrvc.showLoader(false);
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      showCancelButton: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'OK',
      text: 'Transaction Processed Successfully!',
    });
  }
  async createPaymentDetail(PaymentDetailsEntity) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc.createMobilePayPaymentDetail(PaymentDetailsEntity)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  updateMango() {
    const parent = this;

    const formData = parent.myPaymentForm.value;
    parent.paidAmount = numeral(formData.Amount).value();

    const newHeader = {};
    newHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
    newHeader['ClientID'] = parent.companyData.company.ClientID;
    newHeader['CompanyID'] = parent.companyData.company.CompanyID;
    newHeader['PaymentDate'] = new Date();
    newHeader['PaymentType'] = parent.isCrCard ? 'Credit Card' : 'ACH';
    newHeader['PaymentNote'] = "Paid By: " + this.cardProfile.NameOnCard + ", " + "using " + newHeader['PaymentType'] + ":  ****" + this.cardProfile.CardNo + ' (CTP)';
    newHeader['SelectedForDepositToBank'] = false;
    newHeader['NSFtrue'] = false;
    newHeader['BillingPartnerID'] = parent.companyData.company.BillingPartnerID;
    newHeader['OriginatingPartnerID'] = parent.companyData.company.OriginatingPartnerID;
    newHeader['StaffAssignedID'] = parent.companyData.company.StaffAssignedID;
    newHeader['GroupDescriptionIDArray'] = parent.companyData.company.GroupDescriptionIDArray;
    newHeader['GroupDescriptionID'] = parent.companyData.company.GroupDescriptionID;
    newHeader['ClientTypeID'] = parent.companyData.company.ClientTypeID;
    newHeader['BillingHeaderID'] = parent.companyData.company.BillingHeaderID;
    newHeader['PaymentUnapplied'] = 0;
    newHeader['PaymentAmount'] = numeral(formData.Amount).value();
    //newHeader['CreatedbyStaffID'] = staffId ? staffId : null;
    newHeader['isProcessInPortal'] = true; //this is the flag use to modify LastDateEdited if payment is made in portal or apps-payment.
    newHeader['SurChargeAmount'] = numeral(formData.SurChargeAmount).value();
    parent.mangoAPISrvc.showLoader(true);
    if (newHeader["PaymentType"] == 'Credit Card' && this.isDebitCard) {
      newHeader['PaymentType'] = 'Debit card';
    }
    if (parent.companyData.company.InvoiceType == "Retainer Invoice") {
      newHeader['PaymentUnapplied'] = numeral(formData.Amount).value();
      newHeader['DateModifiedMango'] = new Date();
      newHeader['DateCreated'] = new Date();
    }
    // Create Payment Header record
    parent.mangoAPISrvc.createMobilePayPaymentHeader(newHeader).subscribe(async (paymentHeader: any) => {
      const PaymentDetailsEntity = {};
      let paymentAmount = numeral(formData.Amount).value();
      parent.currentInvoiceBalance = 0;
      parent.currentInvoiceBalance = (parent.companyData.company.InvoiceBalance - paymentAmount);
      parent.companyData.company.InvoiceAmount = parent.companyData.company.InvoiceBalance;
      if (parent.isStatementFlow) {
        for (let index = 0; index < parent.companyData.invoices.length; index++) {
          let paymentdetails = {}
          const invoice = parent.companyData.invoices[index];
          if (paymentAmount === 0) {
            parent.isProcessing = false;
            parent.isPaymentCompleted = true;
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', 'Success!', "Proccesed  successfully.");
            parent.sendEmailToFirmAndCustomer(newHeader['PaymentAmount'], paymentHeader.data['PaymentHeaderID']);
            break
          }
          paymentdetails['PaymentHeaderID'] = paymentHeader.data.PaymentHeaderID;
          paymentdetails['ClientID'] = paymentHeader.data.ClientID;
          paymentdetails['CompanyID'] = parent.companyData.company.CompanyID;
          paymentdetails['PaymentNote'] = paymentHeader.data.PaymentNote;
          paymentdetails['PaymentDate'] = paymentHeader.data.PaymentDate;
          paymentdetails['PaymentType'] = paymentHeader.data.PaymentType;
          paymentdetails['InvoiceNumber'] = invoice.InvoiceNumber;
          paymentdetails['InvoiceDate'] = invoice.InvoiceDate;

          if (numeral(invoice.InvoiceBalance).value() > 0 && paymentAmount > 0) {
            if (paymentAmount > numeral(invoice.InvoiceBalance).value()) {
              paymentdetails = {
                ...paymentdetails,
                BillingHeaderID: invoice.BillingHeaderID,
                AppliedAmount: numeral(invoice.InvoiceBalance).value()
              }
              paymentAmount = this.mangoUtils.subtractFloat(paymentAmount, invoice.InvoiceBalance)
              invoice.InvoiceBalance = 0
              // paymentAmount -= invoice.InvoiceBalance
            } else {
              paymentdetails = {
                ...paymentdetails,
                BillingHeaderID: invoice.BillingHeaderID,
                AppliedAmount: paymentAmount
              }
              invoice.InvoiceBalance = this.mangoUtils.subtractFloat(invoice.InvoiceBalance, paymentAmount)
              // invoice.InvoiceBalance = invoice.InvoiceBalance - paymentAmount
              paymentAmount = 0
            }
          }
          await parent.createPaymentDetail(paymentdetails);

          const billingHeaderEntity = {};

          billingHeaderEntity['ClientID'] = paymentHeader.data.ClientID;
          billingHeaderEntity['CompanyID'] = parent.companyData.company.CompanyID;
          billingHeaderEntity['TotalPayments'] = parent.mangoUtils.addFloat(invoice.TotalPayments, paymentdetails['AppliedAmount']);
          billingHeaderEntity['InvoiceBalance'] = invoice.InvoiceBalance
          //parent.currentInvoiceBalance = parent.mangoUtils.addFloat(billingHeaderEntity['InvoiceBalance'], parent.currentInvoiceBalance)
          billingHeaderEntity['PaymentsApplied'] = billingHeaderEntity['TotalPayments'];
          billingHeaderEntity['isProcessInPortal'] = true; //this is the flag use to modify LastDateEdited if payment is made in portal or apps-payment.
          billingHeaderEntity['SurChargeAmount'] = numeral(formData.SurChargeAmount).value();
          // update Billing header
          parent.mangoAPISrvc.billingHeaderMobilePayment(billingHeaderEntity, invoice.BillingHeaderID).subscribe((data) => {
            parent.mangoAPISrvc.showLoader(false);
            if (index == parent.companyData.invoices.length - 1) {
              parent.isProcessing = false;
              parent.isPaymentCompleted = true;
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', 'Success!', "Proccesed  successfully.");
              parent.sendEmailToFirmAndCustomer(newHeader['PaymentAmount'], paymentHeader.data['PaymentHeaderID']);
            }
          }, error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          })
        }
      } else if (parent.companyData.company.InvoiceType == "Retainer Invoice") {

        const billingHeaderRequest = {};
        billingHeaderRequest['PaymentHeaderID'] = paymentHeader.data.PaymentHeaderID;
        billingHeaderRequest['ClientID'] = newHeader['ClientID'];
        billingHeaderRequest['CompanyID'] = newHeader['CompanyID'];
        billingHeaderRequest['InvoiceDate'] = parent.companyData.company.InvoiceDate;
        billingHeaderRequest['DescriptionShort'] = `Advance Payment - ${parent.companyData.company.DescriptionShort} (Retainer Invoice #${parent.companyData.company.InvoiceNumber})`;
        billingHeaderRequest['InvoiceType'] = "Retainer Payment";
        billingHeaderRequest['InvoiceAmount'] = newHeader['PaymentAmount'] * -1;
        billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
        billingHeaderRequest['PaymentsApplied'] = 0;
        billingHeaderRequest['TotalStaffCost'] = 0;
        billingHeaderRequest['TotalPayments'] = 0;
        billingHeaderRequest['InvoicePosted'] = true;
        billingHeaderRequest['NSFtrue'] = false;
        billingHeaderRequest['DateModifiedMango'] = new Date();
        billingHeaderRequest['DateCreated'] = new Date();

        const pendingBalance = numeral(parent.companyData.company.InvoiceAmount).value() - (numeral(parent.companyData.company.TotalPayments).value() + numeral(paymentHeader.data.PaymentAmount).value())

        // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
        if (pendingBalance <= 0) {
          parent.mangoAPISrvc.deleteBillingHeaderMobilePayment(parent.companyData.company.BillingHeaderID, newHeader['CompanyID']).subscribe((response) => { });
        } else {
          const cloneObj = Object.assign({}, billingHeaderRequest);
          cloneObj['AppliedCheckEdit'] = 0;
          cloneObj['PaymentsApplied'] = 0;
          cloneObj['DescriptionShort'] = parent.companyData.company.DescriptionShort;
          cloneObj['InvoiceType'] = "Retainer Invoice";
          cloneObj['InvoiceAmount'] = numeral(parent.companyData.company.InvoiceAmount).value();
          cloneObj['InvoiceBalance'] = pendingBalance;
          cloneObj['PaymentAmount'] = numeral(formData.Amount).value();
          cloneObj['PaymentUnapplied'] = 0;
          cloneObj['TotalPayments'] = numeral(parent.companyData.company.TotalPayments).value() + cloneObj['PaymentAmount'];

          parent.mangoAPISrvc.billingHeaderMobilePayment(cloneObj, parent.companyData.company.BillingHeaderID).subscribe((itemdd) => { });
        }
        billingHeaderRequest["SurChargeAmount"] = numeral(formData.SurChargeAmount).value();
        billingHeaderRequest["SurChargeAmount"] = numeral(formData.SurChargeAmount).value();
        billingHeaderRequest['Token'] = parent.mangoUtils.generateUUID();
        parent.mangoAPISrvc.createBillingHeaderMobilePayment(billingHeaderRequest).subscribe((item: any) => {


          // Need to create PaymentDetail record for the Retainer for association to the BH record
          const PaymentDetailsEntity = {};
          PaymentDetailsEntity['BillingHeaderID'] = item.data.BillingHeaderID;
          PaymentDetailsEntity['PaymentHeaderID'] = paymentHeader.data['PaymentHeaderID'];
          if (numeral(parent.companyData.company.InvoiceBalance).value() != 0) {
            PaymentDetailsEntity['ParentHeaderID'] = parent.companyData.company.BillingHeaderID;
          }
          PaymentDetailsEntity['ClientID'] = paymentHeader.data['ClientID'];
          PaymentDetailsEntity['PaymentNote'] = `Advance Payment - ${parent.companyData.company.DescriptionShort} (Retainer Invoice #${parent.companyData.company.InvoiceNumber})`;
          PaymentDetailsEntity['PaymentDate'] = billingHeaderRequest['InvoiceDate'];
          PaymentDetailsEntity['PaymentType'] = newHeader['PaymentType']
          PaymentDetailsEntity['InvoiceNumber'] = parent.companyData.company.InvoiceNumber;
          PaymentDetailsEntity['InvoiceDate'] = parent.companyData.company.InvoiceDate;
          PaymentDetailsEntity['isAdvancePayment'] = true;
          PaymentDetailsEntity['AppliedAmount'] = newHeader['PaymentAmount'];
          PaymentDetailsEntity['InitialAmount'] = newHeader['PaymentAmount'];
          PaymentDetailsEntity['CompanyID'] = paymentHeader.data['CompanyID'];

          parent.mangoAPISrvc.createMobilePayPaymentDetail(PaymentDetailsEntity).subscribe((item) => {
            setTimeout(() => {
              parent.isProcessing = false;
              parent.isPaymentCompleted = true;
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', 'Success!', "Proccesed  successfully.");
              parent.sendEmailToFirmAndCustomer(newHeader['PaymentAmount'], paymentHeader.data['PaymentHeaderID']);
            }, 500);
          }, error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });
        });
      } else {
        PaymentDetailsEntity['BillingHeaderID'] = parent.companyData.company.BillingHeaderID;
        PaymentDetailsEntity['PaymentHeaderID'] = paymentHeader.data.PaymentHeaderID;
        PaymentDetailsEntity['ClientID'] = paymentHeader.data.ClientID;
        PaymentDetailsEntity['CompanyID'] = parent.companyData.company.CompanyID;
        PaymentDetailsEntity['PaymentNote'] = paymentHeader.data.PaymentNote;
        PaymentDetailsEntity['PaymentDate'] = paymentHeader.data.PaymentDate;
        PaymentDetailsEntity['PaymentType'] = paymentHeader.data.PaymentType;
        PaymentDetailsEntity['InvoiceNumber'] = parent.companyData.company.InvoiceNumber;
        PaymentDetailsEntity['InvoiceDate'] = parent.companyData.company.InvoiceDate;
        PaymentDetailsEntity['AppliedAmount'] = numeral(formData.Amount).value();


        // Create PaymentDetail record
        parent.mangoAPISrvc.createMobilePayPaymentDetail(PaymentDetailsEntity).subscribe((Detailsdata) => {
          const billingHeaderEntity = {};

          billingHeaderEntity['ClientID'] = paymentHeader.data.ClientID;
          billingHeaderEntity['CompanyID'] = parent.companyData.company.CompanyID;
          billingHeaderEntity['TotalPayments'] = numeral(parent.companyData.company.TotalPayments).value() + numeral(formData.Amount).value();
          billingHeaderEntity['InvoiceBalance'] = numeral(parent.companyData.company.InvoiceBalance).value() - numeral(formData.Amount).value();
          parent.currentInvoiceBalance = billingHeaderEntity['InvoiceBalance'];
          billingHeaderEntity['PaymentsApplied'] = billingHeaderEntity['TotalPayments'];
          billingHeaderEntity['isProcessInPortal'] = true; //this is the flag use to modify LastDateEdited if payment is made in portal or apps-payment.
          //billingHeaderEntity['LastModifiedStaffID'] = staffId ? staffId : null;
          billingHeaderEntity['SurChargeAmount'] = numeral(formData.SurChargeAmount).value();
          // update Billing header
          parent.mangoAPISrvc.billingHeaderMobilePayment(billingHeaderEntity, parent.companyData.company.BillingHeaderID).subscribe((data) => {
            parent.isProcessing = false;
            parent.isPaymentCompleted = true;
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify('success', 'Success!', "Proccesed  successfully.");
            parent.sendEmailToFirmAndCustomer(newHeader['PaymentAmount'], paymentHeader.data['PaymentHeaderID']);
          }, error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          });


        }, error => {
          parent.isProcessing = false;
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        })
      }
    }, error => {
      parent.isProcessing = false;
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });

  }

  async changePaymentType() {
    const parent = this;
    this.isBusiness = 'false';
    this.myPaymentForm.controls["SurChargeAmount"].setValue(0);
    this.myPaymentForm.controls["withSurcharge"].setValue(0);
    this.isDebitCard = false;
    let type = this.selectedModeOfPayment > -1 ? ((this.selectedModeOfPayment === 0 || this.selectedModeOfPayment === 2) ? 'CC' : 'ACH') : null;
    let card = this.companyData.cards.filter((item) => item.TransType == type);
    if (type === 'ACH') {
      this.isSelctedPaymentIndex = 1;
    }

    let cardTypeObj;
    if (card.length > 0) {
      cardTypeObj = await this.getCardType(card[0].CustomerCardID, this.companyData.company.CompanyID, 'ct');
    }

    if (!cardTypeObj) {
      this.isStaxNewCard = true;
      this.isSelctedPaymentIndex = 0;      
    }
    else if (cardTypeObj && this.selectedModeOfPayment == 0 && cardTypeObj['bin_type'] !== 'CREDIT') {
      this.isStaxNewCard = true;
      this.isSelctedPaymentIndex = 0;
      this.genarateIFrames();
    }
    else if (cardTypeObj && this.selectedModeOfPayment == 2 && cardTypeObj['bin_type'] !== 'DEBIT') {
      this.isStaxNewCard = true;
      this.isSelctedPaymentIndex = 0;
      this.genarateIFrames();
    }
    else if (
      (cardTypeObj && this.selectedModeOfPayment == 0 && cardTypeObj['bin_type'] === 'CREDIT')
      ||
      (cardTypeObj && this.selectedModeOfPayment == 2 && cardTypeObj['bin_type'] === 'DEBIT')
    ) {
      this.isStaxNewCard = false;
      this.isSelctedPaymentIndex = this.selectedModeOfPayment;
    }
    this.cardProfile = card.length > 0 ? card[0] : null;

    this.isCrCard = (type == "CC") ? true : false;
    this.isDialogFormValid = this.cardProfile ? true : false;

    if (this.cardProfile == null) {
      this.genarateIFrames();
      if (type == "CC") {
        this.addNewStax('card')
      } else {
        this.addNewStax('bank')
      }

      let address: string = "";
      let city: string = "";
      let state: string = "";
      let country: string = "";
      let zip: string = "";

      const defaultClientData = parent.companyData.emails.filter((item) => item.isDefaultContact == true);
      if (defaultClientData && defaultClientData.length > 0) {
        address = defaultClientData[0].Street1 ? defaultClientData[0].Street1 : '';
        city = defaultClientData[0].City ? defaultClientData[0].City : '';
        state = defaultClientData[0].State ? defaultClientData[0].State : '';
        country = defaultClientData[0].Country ? defaultClientData[0].Country : '';
        zip = defaultClientData[0].Zip ? defaultClientData[0].Zip : '';
      } else {
        if (parent.companyData.company.BusStreet1 != null)
          address = parent.companyData.company.BusStreet1;
        else if(parent.companyData.company.BusStreet2 != null)
          address = parent.companyData.company.BusStreet2;
        else
          address = "";
        city = parent.companyData.company.BusCity ? parent.companyData.company.BusCity : '';
        state = parent.companyData.company.BusState ? parent.companyData.company.BusState : '';
        country = parent.companyData.company.BusCountry ? parent.companyData.company.BusCountry : '';
        zip = parent.companyData.company.BusZip ? parent.companyData.company.BusZip : '';
      }
      setTimeout(() => {
        parent.myPaymentForm.controls['LastName'].setValue("");
        parent.myPaymentForm.controls['Address1'].setValue(address);
        parent.myPaymentForm.controls['City'].setValue(city);
        parent.myPaymentForm.controls['State'].setValue(state);
        parent.myPaymentForm.controls['Country'].setValue(country);
        parent.myPaymentForm.controls['Zip'].setValue(zip);          
        parent.myPaymentForm.controls['FirstName'].setValue("");
        this.firstNameInputField.nativeElement.focus();
      }, 500);
    } else {
      if (this.cardProfile && this.cardProfile.CustomerCardID) {
        this.isBusiness = (!this.isCrCard && this.cardProfile.isBusiness == true) ? 'true' : 'false';
        const cardTypeObj = await this.getCardType(this.cardProfile.CustomerCardID, this.companyData.company.CompanyID, 'ct');
        this.isDebitCard = (cardTypeObj['bin_type'] == 'DEBIT') ? true : false;
        if (!this.cardProfile['CardType']) {
          if (type == "CC") {
            this.cardProfile['CardType'] = cardTypeObj['card_type'] ? cardTypeObj['card_type'] : null;
          } else {
            this.cardProfile['CardType'] = cardTypeObj['bank_type'] ? cardTypeObj['bank_type'] : null;
          }
          this.mangoAPISrvc.updateCreditCardDetails(this.cardProfile.CustomerCardID, this.cardProfile).subscribe((resultRes: any) => { });
        }
      }

      if (this.isCrCard && this.cardProfile) {
        if (this.cardProfile && this.companyData.company.isSurchargeEnabled) {
          this.mangoAPISrvc.showLoader(true);
          const surchargeObj = await this.getSurchargeAmount(this.cardProfile.CustomerCardID, this.myPaymentForm.controls['Amount'].value, this.companyData.company.CompanyID, 'sc');
          this.mangoAPISrvc.showLoader(false);
          if (surchargeObj) {
            this.myPaymentForm.controls["SurChargeAmount"].setValue(surchargeObj['surcharge_amount']);
            this.SurChargeAmount = surchargeObj['surcharge_amount'];
            this.myPaymentForm.controls["withSurcharge"].setValue(surchargeObj['total_with_surcharge_amount']);
            this.isDebitCard = (surchargeObj['bin_type'] == 'DEBIT') ? true : false;
          }
        }
        setTimeout(() => {
          this.isDialogFormValid = true;
          this.isProcessing = false;
        }, 200);
      }
    }
  }

  checkStaxEnable() {
    const parent = this;
    if (parent.companyData.company.spkuid) {
      parent.mangoAPISrvc.showLoader(true);
      parent.myPaymentForm.controls['CompanyID'].setValue(parent.companyData.company.CompanyID);
      parent.myPaymentForm.controls['Amount'].setValue(parent.companyData.company.InvoiceBalance);
      parent.mangoAPISrvc.performStaxClickPayActions({}, parent.companyData.company.CompanyID, 'lrv').subscribe(async (response: any) => {
        parent.isApiLoaded = true;
        parent.mangoAPISrvc.showLoader(false);
        if (response && response.underwriting_status != 'PENDED' && parent.companyData.company.StaxStatus == "ACTIVE") {
          parent.isSTaxEnabled = true;
          const email = parent.companyData.company.Email ? parent.companyData.company.Email : response.business_email;
          const address = parent.companyData.company.BusStreet1 ? parent.companyData.company.BusStreet1 : response.business_address_1;
          const address2 = parent.companyData.company.BusStreet2 ? parent.companyData.company.BusStreet2 : response.business_address_2;
          const city = parent.companyData.company.BusCity ? parent.companyData.company.BusCity : response.business_address_city;
          const state = parent.companyData.company.BusState ? parent.companyData.company.BusState : response.business_address_state;
          const country = parent.companyData.company.BusCountry ? parent.companyData.company.BusCountry : response.business_address_country;
          const zip = parent.companyData.company.BusZip ? parent.companyData.company.BusZip : response.business_address_zip;
          let phNo = parent.companyData.company.Mobile ? parent.companyData.company.Mobile : parent.companyData.company.Office;
          phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
          phNo = phNo ? phNo : '555-555-5555';

          parent.myPaymentForm.controls['EmailAddress'].setValue(email);
          parent.myPaymentForm.controls['Address1'].setValue(address);
          parent.myPaymentForm.controls['Address2'].setValue(address2);
          parent.myPaymentForm.controls['City'].setValue(city);
          parent.myPaymentForm.controls['State'].setValue(state);
          parent.myPaymentForm.controls['Country'].setValue(country);
          parent.myPaymentForm.controls['Zip'].setValue(zip);
          parent.myPaymentForm.controls['phone'].setValue(phNo);

          const surchargeData = parent.companyData.cards.filter((item) => item.TransType == 'CC');
          if (surchargeData && parent.modeOfPayment == 0 && surchargeData.length > 0 && surchargeData[0] && parent.companyData.company.isSurchargeEnabled) {
            const surchargeObj = await parent.getSurchargeAmount(surchargeData[0].CustomerCardID, parent.myPaymentForm.controls['Amount'].value, parent.companyData.company.CompanyID, 'sc');
            if (surchargeObj) {
              parent.myPaymentForm.controls["SurChargeAmount"].setValue(surchargeObj['surcharge_amount']);
              this.SurChargeAmount = surchargeObj['surcharge_amount'];
              parent.myPaymentForm.controls["withSurcharge"].setValue(surchargeObj['total_with_surcharge_amount']);
              parent.isDebitCard = (surchargeObj['bin_type'] == 'DEBIT') ? true : false;
            }
          }

        } else {
          if (response && response.underwriting_status == 'PENDED') {
            parent.isSTaxEnabled = false;
            Swal.fire({
              title: 'confirmation',
              html: `<div>Your MangoPayments enrollment has some underwriting issues that require immediate attention!</div>`,
              icon: 'warning',
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: 'Review Now'
            }).then((result) => {
            })
          } else {
            parent.isSTaxEnabled = false;
          }
        }
      }, error => {
        parent.isApiLoaded = true;
        parent.mangoAPISrvc.showLoader(false);
        parent.isSTaxEnabled = false;
        if(error === 'Unauthorized') {
          Swal.fire({
            title: 'STAX is not enabled!',
            html: `<div>Stax is currently not enabled for this firm. If you wish to use this feature, please contact ${parent.companyData.company.Email}.</div>`,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: 'Okay'
          }).then((result) => {
            this._router.navigate(["/login"]);
          })
        }
      });
    } else {
      parent.isApiLoaded = true;
      parent.mangoAPISrvc.showLoader(false);
      parent.isSTaxEnabled = false;
    }
  }

  addNewStax(type) {
    this.isStaxNewCard = true;
    this.isCrCard = false;
    this.isDebitCard = false;
    let address: string = "";
    const city: string = this.companyData.company.BusCity ? this.companyData.company.BusCity : '';
    const state: string = this.companyData.company.BusState ? this.companyData.company.BusState : '';
    const country: string = this.companyData.company.BusCountry ? this.companyData.company.BusCountry : '';
    const zip: string = this.companyData.company.BusZip ? this.companyData.company.BusZip : '';
    if (this.companyData.company.BusStreet1 != null)
      address = this.companyData.company.BusStreet1;
    else if (this.companyData.company.BusStreet2 != null)
      address = this.companyData.company.BusStreet2;
    else
      address = "";    
    if (type == 'card') {
      this.isCrCard = true;
      this.addCardValidators(this.requiredcardFields);
      setTimeout(() => {
        this.myPaymentForm.controls['Address1'].setValue(address);
        this.myPaymentForm.controls['Address2'].setValue("");
        this.myPaymentForm.controls['City'].setValue(city);
        this.myPaymentForm.controls['State'].setValue(state);
        this.myPaymentForm.controls['Zip'].setValue(zip);
        this.myPaymentForm.controls['Country'].setValue(country);
        this.myPaymentForm.controls['FirstName'].setValue("");
        this.firstNameInputField.nativeElement.focus();
      }, 500);
    } else {
      this.addCardValidators(this.requiredACHFields);
    }
    this.myPaymentForm.controls["SurChargeAmount"].setValue(0);
    this.myPaymentForm.controls["withSurcharge"].setValue(0);
  }

  processPayment() {
    if (this.isProcessing) {
      return false;
    }
    this.isProcessing = true;
    if (this.isStaxNewCard) {
      this.addSTaxNewCard();
    } else {
      this.processSTaxUsingToken();
    }
  }

  authorizeSTaxNewCard() {
    if (this.isStaxNewCard) {
      this.addSTaxNewCard();
    } else {
      this.processSTaxUsingToken();
    }
  }

  async getCardType(customerCardID, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxClickPayActions({ ccid: customerCardID }, companyId, eventType)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async addSTaxNewCard() {
    const parent = this;
    let extraDetails = null;
    parent.myPaymentForm.controls['isBusiness'].setValue(parent.isBusiness);
    const formData = parent.myPaymentForm.value;
    const cardObj = {};
    let phNo = parent.companyData.company.Mobile ? parent.companyData.company.Mobile : parent.companyData.company.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '';
    phNo = phNo ? phNo : '';

    let customerId = ""
    const existingCard = parent.companyData.cards.filter((item) => item.TransType == parent.isCrCard ? 'CC' : 'ACH');
    if (existingCard && existingCard.length > 0) {
      customerId = existingCard && existingCard.length > 0 ? existingCard[0]?.scid : ""
    } else {
      const customerObj = {
        firstname: formData.FirstName,
        lastname: formData.LastName,
        phone: phNo,
        email: formData.EmailAddress,
        address_1: formData.Address1,
        address_city: formData.City,
        address_state: formData.State,
        address_zip: formData.Zip
        // address_1: parent.isCrCard ? formData.Address1 : parent.companyData.company.BusStreet1,
        // address_city: parent.isCrCard ? formData.City : parent.companyData.company.BusCity,
        // address_state: parent.isCrCard ? formData.State : parent.companyData.company.BusState,
        // address_zip: parent.isCrCard ? formData.Zip : parent.companyData.company.BusZip
      }
      if (!phNo) delete customerObj.phone
      if (!parent.companyData.company.Email) delete customerObj.email
      try {
        const responseCust = await parent.createStaxCustomer(customerObj)
        customerId = responseCust['id']
      } catch (errCust) {
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = ''
        if (!msg) {
          let errCount = 0;
          if (errCust.address_zip && errCust.address_zip.length > 0) {
            msg += "Phone #";
            errCount++
          }
          if (errCust.address_1 && errCust.address_1.length > 0) {
            msg += errCount > 0 ? ", Address 1" : "Address 1";
            errCount++
          }
          if (errCust.address_2 && errCust.address_2.length > 0) {
            msg += errCount > 0 ? ", Address 2" : "Address 2";
            errCount++
          }
          if (errCust.address_city && errCust.address_city.length > 0) {
            msg += errCount > 0 ? ", city" : "city";
            errCount++
          }
          if (errCust.address_state && errCust.address_state.length > 0) {
            msg += errCount > 0 ? ", state" : "state";
            errCount++
          }
          if (errCust.address_zip && errCust.address_zip.length > 0) {
            msg += errCount > 0 ? ", and zip" : "zip";
            errCount++
          }
          if (errCount > 0) msg += " are invalid!"
          else msg += " is invalid!"
        }
        Swal.fire({
          icon: "error",
          title: parent.translate.instant("Transaction Not Approved"),
          text: msg,
          showConfirmButton: false,
          timer: 7000,
        });
        return;
      }
    }

    parent.mangoAPISrvc.showLoader(true);
    if (this.isCrCard) {
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        email: formData.EmailAddress,
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        phone: formData.phone,
        address_1: formData.Address1,
        //address_2: formData.Address2 ? formData.Address2 : "",
        address_city: formData.City,
        address_state: formData.State,
        address_zip: formData.Zip,
        method: 'card',
        validate: false,
        send_receipt: false,
        match_customer: false,
        meta: {
          otherField1: "from Click to Pay : New card :  | InvoiceNumber : " + this.companyData.company.InvoiceNumber + " | ClientName :" + this.companyData.company['ClientName']
            + " | CompanyID :" + this.companyData.company['CompanyID'] + " | ClientID :" + this.companyData.company['ClientID']
        }
      };
    } else {
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        person_name: formData.FirstName + ' ' + formData.LastName,
        phone: formData.phone,
        address_1: formData.Address1,
        address_2: formData.Address2 ? formData.Address2 : "",
        address_city: formData.City,
        address_state: formData.State,
        address_zip: formData.Zip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: (formData.isBusiness == 'true') ? "business" : "personal",
        method: 'bank',
        validate: false,
        send_receipt: false,
        match_customer: false,
        url: "https://omni.fattmerchant.com/#/bill/",
        meta: {
          otherField1: "from Click to Pay : New card :  | InvoiceNumber : " + this.companyData.company.InvoiceNumber + " | ClientName :" + this.companyData.company['ClientName']
            + " | CompanyID :" + this.companyData.company['CompanyID'] + " | ClientID :" + this.companyData.company['ClientID']
        }
      };
    }
    this.fattJs.tokenize(extraDetails).then((result) => {
      if (result.id) {
        if (result.bin_type == 'DEBIT' && this.selectedModeOfPayment !== 2) {
          Swal.fire({
            icon: "error",
            title: "Transaction Not Approved",
            text: "Please use a debit card for this transaction.",
            showConfirmButton: false,
            timer: 7000,
          });
          parent.isProcessing = false;
          parent.mangoAPISrvc.showLoader(false);
          return;
        }
        else if (result.bin_type == 'CREDIT' && this.selectedModeOfPayment !== 0) {
          Swal.fire({
            icon: "error",
            title: "Transaction Not Approved",
            text: "Please use a credit card for this transaction.",
            showConfirmButton: false,
            timer: 7000,
          });
          parent.isProcessing = false;
          parent.mangoAPISrvc.showLoader(false);
          return;
        }
        parent.isProcessing = false;
        cardObj['match_customer'] = false;
        cardObj['CompanyID'] = parent.companyData.company.CompanyID;
        cardObj['ClientID'] = parent.companyData.company.ClientID;
        cardObj['CardNo'] = result.card_last_four;
        cardObj['ExpiryDate'] = result.card_exp;
        cardObj['NameOnCard'] = result.person_name;
        cardObj['TransType'] = parent.isCrCard ? 'CC' : 'ACH';
        cardObj['FirstName'] = formData.FirstName;
        cardObj['LastName'] = formData.LastName;
        cardObj['uid'] = result.id;
        cardObj['scid'] = result.customer_id;
        cardObj['CardType'] = parent.isCrCard ? result['card_type'] : result['bank_type'];
        if (!parent.isCrCard) {
          cardObj['BankAcctNo'] = formData['AccountNumber'].substr(formData['AccountNumber'].length - 4, formData['AccountNumber'].length);
          cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
        }
        parent.cardProfile = parent.companyData.cards.filter((item) => item.TransType == cardObj['TransType']);

        if (parent.cardProfile == null || parent.cardProfile.length == 0) {
          parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(async (resultRes: any) => {
            parent.cardProfile = cardObj;
            parent.cardProfile['CustomerCardID'] = resultRes.id;
            parent.companyData.cards.push(parent.cardProfile);
            parent.mangoAPISrvc.showLoader(false);
            parent.isStaxNewCard = false;
            await parent.calculateSurcharge();
          },
            (data: any) => { parent.mangoAPISrvc.showLoader(false) }
          );
        } else {
          parent.cardProfile = parent.cardProfile[0];
          parent.mangoAPISrvc.updateCreditCardDetails(parent.cardProfile.CustomerCardID, cardObj).subscribe(async (resultRes: any) => {
            parent.cardProfile['StaxToken'] = cardObj['StaxToken'];
            parent.cardProfile['NameOnCard'] = cardObj['NameOnCard'];
            parent.cardProfile['CardNo'] = cardObj['CardNo'];
            parent.cardProfile['CardType'] = cardObj['CardType'];
            // parent.cardProfile['StaxCustomerID'] = cardObj['StaxCustomerID'];
            // parent.cardProfile['StaxPaymentMethodID'] = cardObj['StaxToken'];
            parent.mangoAPISrvc.showLoader(false);
            parent.isStaxNewCard = false;
            await parent.calculateSurcharge();
          },
            (data: any) => { parent.mangoAPISrvc.showLoader(false) }
          );
        }
      }
    }).catch(err => {
      parent.isProcessing = false;
      parent.mangoAPISrvc.showLoader(false);
      parent.isDialogFormValid = true;
      let msg = err['message'] ? err['message'] : null;
      if (!msg) {
        if (err.payment_attempt_message) {
          msg = err.payment_attempt_message;
        } else if (err.child_transactions && err.child_transactions.length > 0) {
          msg = err.child_transactions[0].message;
        } else if (err.error_description) {
          msg = err.error_description;
        } else {
          try {
            msg = typeof err === 'object' ? Object.keys(err).map((k) => err[k].join(' ')).join(' ') : JSON.stringify(err);
          } catch (error) {
          }
        }
      }
      Swal.fire({
        icon: "error",
        title: parent.translate.instant("Transaction Not Approved"),
        text: msg,
        showConfirmButton: false,
        timer: 7000,
      });
    });
  }

  async createStaxCustomer(customerObj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      parent.mangoAPISrvc.performStaxClickPayActions({
        data: customerObj
      }, parent.companyData.company.CompanyID, 'cc').subscribe((result) => {
        parent.mangoAPISrvc.showLoader(false); resolve(result)
      }, error => {
        reject(error);
      })
    });
  }

  prepareMangoData() {
    const parent = this;
    parent.processData = { "Amount": null, "ClientID": null, "CompanyID": null, "PaymentType": null, "PaymentNote": null, "BillingPartnerID": null, "OriginatingPartnerID": null, "StaffAssignedID": null, "GroupDescriptionIDArray": null, "GroupDescriptionID": null, "ClientTypeID": null, "BillingHeaderID": null, "InvoiceType": null, "invoices": [] };

    const formData = parent.myPaymentForm.value;
    parent.processData.Amount = parent.processData['PaymentAmount'] = numeral(formData.Amount).value();
    parent.processData.ClientID = parent.companyData.company.ClientID;
    parent.processData.CompanyID = parent.companyData.company.CompanyID;
    parent.processData.BillingPartnerID = parent.companyData.company.BillingPartnerID;
    parent.processData.OriginatingPartnerID = parent.companyData.company.OriginatingPartnerID;
    parent.processData.StaffAssignedID = parent.companyData.company.StaffAssignedID;
    parent.processData.GroupDescriptionIDArray = parent.companyData.company.GroupDescriptionIDArray;
    parent.processData.GroupDescriptionID = parent.companyData.company.GroupDescriptionID;
    parent.processData.ClientTypeID = parent.companyData.company.ClientTypeID;
    parent.processData.BillingHeaderID = parent.companyData.company.BillingHeaderID;
    parent.processData.PaymentType = parent.isCrCard ? 'Credit Card' : 'ACH';
    if (this.selectedModeOfPayment === 0) {
      this.processData.PaymentNote = "Paid By: " + this.cardProfile.NameOnCard + ", using CREDIT CARD: ****" + this.cardProfile.CardNo + ' (CTP)';
    } else if (this.selectedModeOfPayment === 2) {
      this.processData.PaymentNote = "Paid By: " + this.cardProfile.NameOnCard + ", using DEBIT CARD: ****" + this.cardProfile.CardNo + ' (CTP)';
    } else {
      this.processData.PaymentNote = "Paid By: " + this.cardProfile.NameOnCard + ", using " + this.processData['PaymentType'] + ": ****" + this.cardProfile.CardNo + ' (CTP)';
    }
    parent.processData['SurChargeAmount'] = numeral(formData.SurChargeAmount).value();
    parent.processData['PaymentUnapplied'] = 0;
    parent.processData.InvoiceType = parent.companyData.company.InvoiceType;
    parent.processData.invoices = parent.companyData.invoices;

    if (parent.processData["PaymentType"] == 'Credit Card' && this.isDebitCard) {
      parent.processData['PaymentType'] = 'Debit card';
    }

    if (parent.processData.InvoiceType == "Retainer Invoice") {
      parent.processData['PaymentUnapplied'] = numeral(formData.Amount).value();
    }

    parent.processData['isStatementFlow'] = parent.isStatementFlow;
    parent.processData['DescriptionShort'] = parent.companyData.company.DescriptionShort;
    parent.processData['InvoiceNumber'] = parent.companyData.company.InvoiceNumber;
    parent.processData['InvoiceAmount'] = parent.companyData.company.InvoiceAmount;
    parent.processData['TotalPayments'] = parent.companyData.company.TotalPayments;
    parent.processData['BillingHeaderID'] = parent.companyData.company.BillingHeaderID;
    parent.processData['InvoiceBalance'] = parent.companyData.company.InvoiceBalance;
    parent.processData['InvoiceDate'] = parent.companyData.company.InvoiceDate;
    parent.processData['isClickToPay'] = true;
    return parent.processData;

  }

  async processSTaxUsingToken() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    const formData = parent.myPaymentForm.value;
    const cardObj = { mangodata: null, cardData: { method: parent.isCrCard ? 'card' : 'bank', CustomerCardID: this.cardProfile.CustomerCardID, send_receipt: false, meta: { tax: 0, shippingAmount: 0, customerCode: parent.companyData.company.ClientID, otherField1: "from Click to Pay : Tokenize card | InvoiceNumber : " + this.companyData.company.InvoiceNumber + " | ClientName :" + this.companyData.company['ClientName'], subtotal: numeral(formData.Amount).value() }, total: numeral(formData.Amount).value() } };

    // fetch latest amount 
    const updatedBal = await parent.fetchUpdatedDataFromToken(parent.token);

    if (updatedBal == 0 || updatedBal != numeral(parent.companyData.company.InvoiceBalance).value()) {
      Swal.fire({
        title: 'Warning',
        html: `<div>Invoice Balance has changed. Please refresh the screen.!</div>`,
        icon: 'warning',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Refresh Now'
      }).then((result) => {
        parent.nativeWindow.location.reload();
      })
    } else {
      cardObj.mangodata = parent.prepareMangoData();
      parent.mangoAPISrvc.performStaxClickPayActions(cardObj, parent.companyData.company.CompanyID, 'c').subscribe((result: any) => {
        parent.transactionData = result;
        parent.currentInvoiceBalance = 0;
        const paymentAmount = numeral(formData.Amount).value();
        parent.currentInvoiceBalance = (parent.companyData.company.InvoiceBalance - paymentAmount);
        parent.isPaymentCompleted = true;
        parent.mangoAPISrvc.showLoader(false);
        const obj = {};
        obj['uid'] = (parent.transactionData ? parent.transactionData.receiptID : null);
        obj['isBusiness'] = (!parent.isCrCard && parent.isBusiness == 'true') ? true : false;
        parent.mangoAPISrvc.updateCreditCardDetails(parent.cardProfile.CustomerCardID, obj).subscribe((resultRes: any) => { });
        parent.sendEmailToFirmAndCustomer(parent.transactionData['PaymentAmount'], parent.transactionData['PaymentHeaderID']);
        //parent.updateMango();
      },
        (err: any) => {
          parent.isProcessing = false;
          parent.mangoAPISrvc.showLoader(false);
          let msg = err['message'] ? err['message'] : err;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg = typeof err === 'object' ? Object.keys(err).map((k) => err[k].join(' ')).join(' ') : JSON.stringify(err);
              } catch (error) {
              }
            }
          }
          Swal.fire({
            icon: "error",
            title: parent.translate.instant("Transaction Not Approved"),
            text: msg,
            showConfirmButton: false,
            timer: 7000,
          });
        }
      );
    }
  }

  focusCard(index, element) {
    const iframeRef = $('iframe')[index];
    $(iframeRef).focus();
    $(iframeRef).contents().find(`#${element}`).focus();
  }

  genarateIFrames() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      self.fattJs = new FattJs(self.companyData.company.spkuid, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          format: 'prettyFormat',
          style: 'width: 179px; height:90%; border-radius: 3px; border: 1px solid #ced4da; padding: .5em .5em; font-size: 91%;'
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style: 'width: 179px; height:90%; border-radius: 3px; border: 1px solid #ced4da; padding: .5em .5em; font-size: 91%;'
        }
      });

      self.fattJs.showCardForm().then(handler => {
      })
        .catch(err => {
        });

      self.fattJs.on('card_form_complete', (message) => {
      });

      self.fattJs.on('card_form_incomplete', (message) => {
      });
    }, 3000);
  }

  applyFullAmount() {
    this.myPaymentForm.controls["Amount"].setValue(this.companyData.company.InvoiceBalance);
    this.calculateSurcharge();
    this.isFullPayment = 'true';
  }

  applyPartialAmount() {
    this.isFullPayment = 'false';
  }

  async calculateSurcharge() {
    this.myPaymentForm.controls["SurChargeAmount"].setValue(0);
    this.myPaymentForm.controls["withSurcharge"].setValue(0);
    this.isDebitCard = false;
    if (this.cardProfile && !this.isStaxNewCard && this.companyData.company.isSurchargeEnabled) {
      this.mangoAPISrvc.showLoader(true);
      const processAmt = numeral(this.myPaymentForm.get("Amount").value).value();
      const surchargeObj = await this.getSurchargeAmount(this.cardProfile.CustomerCardID, processAmt, this.companyData.company.CompanyID, 'sc');
      if (surchargeObj) {
        this.myPaymentForm.controls["SurChargeAmount"].setValue(surchargeObj['surcharge_amount']);
        this.SurChargeAmount = surchargeObj['surcharge_amount'];
        this.myPaymentForm.controls["withSurcharge"].setValue(surchargeObj['total_with_surcharge_amount']);
        this.isDebitCard = (surchargeObj['bin_type'] == 'DEBIT') ? true : false;
      }
      this.mangoAPISrvc.showLoader(false);
    }

  }

  async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxClickPayActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
          .subscribe(function (data) {
            data = data['bin_type'] ? data : null;
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  isValidStaxForm() {
    const paymentFormData = this.myPaymentForm.value;
    this.isProcessing = false;
    if (this.isCrCard && this.isStaxNewCard && (!paymentFormData.FirstName || !paymentFormData.LastName || !paymentFormData.Address1 || !paymentFormData.City || !paymentFormData.State || !paymentFormData.Zip || !paymentFormData.expDate)) {
      this.isProcessing = true;
    } else if (!this.isCrCard && this.isStaxNewCard && (!paymentFormData.RoutingNumber || !paymentFormData.AccountNumber || !paymentFormData.TransCode || !paymentFormData.FirstName || !paymentFormData.LastName)) {
      this.isProcessing = true;
    }
  }
}
