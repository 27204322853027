<div class="layout-topbar">
  <div class="layout-topbar-wrapper">
    <div class="layout-topbar-left">
      <div class="layout-topbar-logo-wrapper" [routerLink]="logoLink">
        <a href="javascript: void(0)" class="layout-topbar-logo">
          <img alt="logo" src="assets/img/new-logo.svg" />
        </a>
        </div>

      <a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>

      <a href="#" class="megamenu-mobile-button" (click)="app.onMegaMenuMobileButtonClick($event)">
        <i class="pi pi-align-right megamenu-icon"></i>
      </a>

      <a href="#" class="topbar-menu-mobile-button" (click)="app.onTopbarMobileMenuButtonClick($event)">
        <i class="pi pi-ellipsis-v"></i>
      </a>

      <div class="layout-megamenu-wrapper">
        <a class="layout-megamenu-button" href="#" (click)="app.onMegaMenuButtonClick($event)" style="white-space: nowrap;">
          <i class="fal fa-link" style="font-size:16px"></i>
          {{ 'QuickLinks' | translate }}
        </a>
      

        <ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': app.megaMenuActive}"
            (click)="app.onMegaMenuClick($event)">
          <li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaMenuItemClick(1)">

            <ul>
              <li class="active-row" routerLinkActive="active-link" (click)="redirectClient('New')">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.client-setup' | translate }}</h5>
                  <!-- Masculine Translation for Spanish -->
                  <span>{{ 'QuickLinks.client-setup-subtitle' | translate }} !</span>
                </span>
              </li>
              <li (click)="openShortCutsModal()">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.shortcut-codes' | translate }}</h5>
                  <span>{{ 'QuickLinks.shortcut-codes-subtitle' | translate }}!</span>
                </span>
              </li>
              <li *ngIf="subscriptionLevel !== 'PM'" routerLinkActive="active-link" (click)="app.onLayoutClick()"
                  routerLink="/billing-invoicing/manualInvoice">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.manual-invoice' | translate }} </h5>
                  <span>{{ 'QuickLinks.manual-invoice-subtitle' | translate }}!</span>
                </span>
              </li>
              <li (click)="onNewUpdatesAvailable()" *ngIf="updateHistoryVisible.includes(companyId)">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.Version-upgraded' | translate }}</h5>
                  <span>{{ 'QuickLinks.Version-upgraded-subtitle' | translate }}!</span>
                </span>
              </li>
              </ul>
              </li>
          <li *ngIf="subscriptionLevel !== 'PM'" [ngClass]="{'active-topmenuitem': activeItem === 2}"
              (click)="mobileMegaMenuItemClick(2)">

            <ul>
              <li (click)="openTimeSheetModal()">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.time-entry' | translate }}</h5>
                  <span>{{ 'QuickLinks.time-entry-subtitle' | translate }}!</span>
                </span>
              </li>
              <li (click)="openExpenseModal()">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.expense-entry' | translate }}</h5>
                  <span>{{ 'QuickLinks.expense-entry-subtitle' | translate }}!</span>
                </span>
              </li>
              <li routerLinkActive="active-link" (click)="app.onLayoutClick()" routerLink="/accounting/receipts">
                <i class="pi pi-circle-on"></i>
                <span>
                  <h5>{{ 'QuickLinks.cash-receipts' | translate }}</h5>
                  <span>{{ 'QuickLinks.cash-receipts-subtitle' | translate }} !</span>
                </span>
              </li>
              </ul>
              </li>
              <li [ngClass]="{'active-topmenuitem': activeItem === 3}" (click)="mobileMegaMenuItemClick(3)">
                <ul>
                  <li (click)="openNotesModal()">
                    <i class="pi pi-circle-on"></i>
                    <span>
                      <h5>{{ 'QuickLinks.client-notes' | translate }}</h5>
                      <span>{{ 'QuickLinks.client-notes-subtitle' | translate }}!</span>
                    </span>
                  </li>
                  <li (click)="openToDoModal()">
                    <i class="pi pi-circle-on"></i>
                    <span>
                      <h5>{{ 'QuickLinks.to-do' | translate }}</h5>
                      <span>{{ 'QuickLinks.to-do-subtitle' | translate }}!</span>
                    </span>
                  </li>
                  <li (click)="openLanguageKeysModal()" *ngIf="isSuperAdmin">
                    <i class="pi pi-circle-on"></i>
                    <span>
                      <h5>{{ 'QuickLinks.language-keys' | translate }}</h5>
                      <span>{{ 'QuickLinks.language-keys-subtitle' | translate }}!</span>
                    </span>
                  </li>
                </ul>
              </li>
              </ul>

      </div>
      <div class="p-text-center" *ngIf="showTrialCountdown" style="width: 100%;">
        <span class="ic-red ic-size-18">Days Remaining in FREE TRIAL - {{trialDaysLeft}}
          Days</span>
        <button type="button" style="height: fit-content;" class="p-ml-3 p-p-1 bg-salmon p-button-sm" pButton pRipple label="{{ 'trial.signup_now' | translate }}"
          (click)="showSignupDialog()"></button>
      </div>

    </div>
    <div class="layout-topbar-right fadeInDown">
      <ul class="layout-topbar-actions">
        <!-- <li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === search}">
      <a href="#" class="topbar-search-mobile-button" (click)="app.onTopbarItemClick($event,search)">
        <i class="topbar-icon pi pi-search"></i>
      </a>
      <ul class="search-item-submenu fadeInDown" (click)="app.topbarItemClick = true">
        <li>
                            <span class="md-inputfield search-input-wrapper">
                                <input pInputText placeholder="Search..."/>
                                <i class="pi pi-search"></i>
                            </span>
                        </li>
                    </ul>
    </li> -->
        <!-- ******************** Cr card *************************************** -->
        <!-- <li #ccard class="topbar-item" *ngIf="superAdminVisible.includes(companyId)">
    <a href="javascript:void(0);" (click)="ccardrouter()">
    <i class="topbar-icon fab fa-cc-amazon-pay" title="S Tax"></i>
    </a>
    </li> -->

        <!-- ******************** Refresh ***************************************

    <li #refresh class="topbar-item" *ngIf="superAdminVisible.includes(companyId)">
    <a href="javascript:void(0);"  (click)="testRouter()">
    <i class="topbar-icon fal fa-file-spreadsheet" title="DMS"></i>
    </a>
    </li>
    -->
        <li #refresh class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === refresh}">
          <a href="javascript:void(0);" (click)="refreshCache()">
            <i class="topbar-icon fal fa-sync" title="Refresh"></i>
          </a>
        </li>

        <!-- ******************** Toggle Menu *************************************** -->

        <li #toggleMenu class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === toggleMenu}">
          <a href="javascript:void(0);" (click)="onToggleMenu()">
            <i *ngIf="!isMenuHorizontal" class="topbar-icon fal fa-bars" title="Toggle Menu"></i>
            <i *ngIf="isMenuHorizontal" class="topbar-icon fal fa-bars" title="Toggle Menu"></i>
          </a>
        </li>

        <!-- ******************** TIMERS *************************************** -->
        <li #timer class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === timer}"
            *ngIf="subscriptionLevel !== 'PM'">
          <a href="#" (click)="app.onTopbarItemClick($event,timer)">
            <div *ngIf="timerFlag; else noTimers">
              <i class="topbar-icon fal fa-stopwatch" style="color: #fa6930 !important;" title="Create a Timer"> </i>
            </div>
            <ng-template #noTimers>
              <i class="topbar-icon fal fa-stopwatch" title="Create a Timer"> </i>
            </ng-template>
          </a>
          <ul class="fadeInDown timer-width">
            <li class="layout-submenu-header">
              <h1>{{ 'timer' | translate }}</h1>
            </li>
            <app-timer-list (appendTasksList)="appendTasksList($event)"></app-timer-list>
            <!-- <li class="p-p-2">
          <p-button class="pull-right p-mx-2 p-mt-2" icon="fal fa-plus" label="{{ 'timer.add-timer' | translate }}" (click)="openTimerModal()"></p-button>
        </li> -->
          </ul>
          </li>

        <!-- ******************** CLIENTS *************************************** -->
        <li #clients class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === clients}">
          <a href="javascript:void(0);" routerLinkActive="active-link" (click)="redirectClient('list')">
            <i class="topbar-icon fal fa-user-friends" title="Client List"></i>
            </a>
            </li>
            <!-- ******************** CALENDAR *************************************** -->

        <li #calendar class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === calendar}"
            *ngIf="subscriptionLevel !== 'PM'">
          <a href="javascript:void(0);" (click)="app.onTopbarItemClick($event,calendar)" routerLinkActive="active-link"
              routerLink="/scheduling">
            <i class="topbar-icon fal fa-calendar-alt" title="Appointments"></i>
          </a>
        </li>

        <!-- ******************** SUPPORT *************************************** -->
        <li #message class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === message}">
          <a href="#" (click)="app.onTopbarItemClick($event,message)">
            <i class="topbar-icon fad fa-question" title="Support Options"></i>
          </a>
          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <h1>{{ 'Support' | translate }}</h1>
              <span>{{ 'Support.subtitle' | translate }}!</span>
            </li>

            <li class="layout-submenu-item" style="
            position: relative
          ">
              <i class="fal fa-comment-lines chat-label ic-size-20 p-pr-6">
              </i>
              <div class="menu-text p-pr-6 p-mr-3">
                <a (click)="openAndCloseChat()" style="
                position:absolute;
                height:100%;
                width:100%;
                top: 0;
                left: 0
              ">
                </a>
                <p>
                  {{ 'Support.inapp-chat' | translate }}
                </p>
                <span>
                  {{ 'Support.inapp-chat-subtitle' | translate }}!
                </span>
              </div>
            </li>

            <li class="layout-submenu-item" style="position: relative">
              <i class="fal fa-newspaper  ic-size-20 p-pr-5"></i>
              <div class="menu-text p-pr-4">
                <a href="https://intercom.help/mango-billing" target="_blank"
                    style="position:absolute; height:100%; width:100%; top: 0; left: 0"></a>
                <p>{{ 'Support.help-articles' | translate }}</p>
                <span>{{ 'Support.help-articles-subtitle' | translate }}</span>
                </div>
                </li>

            <li class="layout-submenu-item" style="position: relative">
              <i class="fal fa-video  ic-size-20 p-pr-5"></i>
              <div class="menu-text p-pr-4">
                <a href="https://qsmf5j.axshare.com/#id=hoc598&p=learning_center&c=1" target="_blank"
                    style="position:absolute; height:100%; width:100%; top: 0; left: 0"></a>
                <p>{{ 'Support.learning-center-videos' | translate }}</p>
                <span>{{ 'Support.learning-center-videos-subtitle' | translate }}</span>
                </div>
                </li>

            <li *ngIf="false" class="
            layout-submenu-item
          ">
              <i class="
              fal
              fa-ticket
              ic-size-20
              p-pr-4
            ">
              </i>
              <div class="
              menu-text
              p-pr-4
            " style="
              position: relative
            ">
                <a href="https://us02web.zoom.us/j/5744172268" target="_blank" style="
                position:absolute;
                height:100%;
                width:100%;
                top: 0;
                left: 0;
              ">
                </a>
                <p>
                  {{ 'Support.remote-support' | translate }}
                </p>
                <span>
                  {{ 'Support.remote-support-subtitle' | translate }}
                </span>
              </div>
            </li>

            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="redirectMyProfile('updateHistory')">
              <i class="fal fa-history ic-size-20 p-pr-4"></i>
              <div class="menu-text p-pr-2">
                <p>{{ 'admin.update_history' | translate }}</p>
                <span>{{ 'admin.update_history_subtitle' | translate }}</span>
              </div>
            </li>
            </ul>
            </li>

        <!-- ******************** SETTINGS *************************************** -->
        <li #settings *ngIf="isShowSettingsIcon" class="topbar-item"
            [ngClass]="{'active-topmenuitem': app.activeTopbarItem === settings}">
          <a href="#" (click)="app.onTopbarItemClick($event,settings)">
            <i class="topbar-icon fad fa-cogs" title="General Settings"></i>
          </a>
          <ul class="fadeInDown profile-item">
            <li class="layout-submenu-header">
              <h1>{{ 'Settings' | translate }} </h1>
              <span>{{ 'Settings.subtitle' | translate }}!</span>
            </li>
            <li class="layout-submenu-item p-d-flex p-jc-around" routerLinkActive="active-link" (click)="app.onLayoutClick()"
                [routerLink]="settingsLink">
              <i class="fal fa-cogs ic-size-20 p-pr-5"></i>
              <div class="menu-text p-pr-4">
                <p>{{ 'Settings.general-settings' | translate }}</p>
                <span>{{ 'Settings.general-settings-subtitle' | translate }}</span>
              </div>
            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="app.onLayoutClick()"
                routerLink="/companySettings">
              <i class="fal fa-building ic-size-20 p-pr-5 p-mr-1"></i>
              <div class="menu-text p-pr-5">
                <p>{{ 'Settings.company-information' | translate }}</p>
                <span>{{ 'Settings.company-information-subtitle' | translate }}</span>
              </div>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="app.onLayoutClick()" (click)="redirectUser()">
              <i class="fal fa-users ic-size-20 p-pr-5"></i>
              <div class="menu-text p-pr-5">
                <p>{{ 'Settings.user-team-members' | translate }}</p>
                <span>{{ 'Settings.user-team-members-subtitle' | translate }}</span>
              </div>
            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="app.onLayoutClick()" routerLink="/manageData"
                *ngIf="subscriptionLevel !== 'FREE' && subscriptionLevel !== 'PM'">
              <i class="fal fa-cloud-upload ic-size-20 p-pr-5"></i>
              <div class="menu-text p-pr-4">
                <p>{{ 'Settings.data-import-export' | translate }}</p>
                <span>{{ 'Settings.data-import-export-subtitle' | translate }}</span>
              </div>
            </li>
            <li class="layout-submenu-item" (click)="app.onLayoutClick()" routerLink="/taxImports"
                *ngIf="subscriptionLevel !== 'FREE' && subscriptionLevel !== 'PM'">
              <i class="fal fa-upload ic-size-20 p-pl-3 p-pr-5"></i>
              <div class="menu-text p-ml-1">
                <p>{{ 'Settings.tax-import' | translate }}</p>
                <span>{{ 'Settings.import-tax-subtitle' | translate }}</span>
              </div>
            </li>
            </ul>
            </li>

        <li #profile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
          <a href="#" (click)="app.onTopbarItemClick($event,profile)">
            <span class="profile-image-wrapper">
              <img [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                  onError="this.src='assets/mango/img/user.png'" alt="{{loggedInUser}}" class="user-header-img" />
            </span>
            <span class="profile-info-wrapper">
              <h3 class="label-text">{{loggedInUser}}</h3>
              <span>{{userDept}}</span>
            </span>
          </a>
          <ul class="profile-item-submenu fadeInDown">
            <li class="profile-submenu-header">
              <div class="performance">
                <span>{{ 'user.weekly' | translate }} {{ 'Performance' | translate }}</span>
                <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />
              </div>
              <div class="profile">
                <img [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'"
                    onError="this.src='assets/mango/img/user.png'" width="40" height="40" alt="{{loggedInUser}}"
                    class="user-header-img" />
                <h1>{{loggedInUser}}</h1>
                <span>{{userDept}}</span>
              </div>
            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="redirectMyProfile('myProfile')">
              <i class="pi pi-user icon icon-1 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'My_Profile' | translate }}</p>
                <span>{{ 'user.edit_profile' | translate }} & {{ 'user.change_password' | translate }}</span>

              </div>

            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="redirectMyProfile('labelPrinting')">
              <i class="pi pi-print icon icon-2 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'user.label_printing' | translate }}</p>
                <span>{{ 'user.dymo_print' | translate }}</span>
              </div>

            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="redirectMyProfile('emailAutomation')">
              <i class="pi pi-envelope icon icon-2 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'user.email_automation' | translate }}</p>
                <span>{{ 'user.email_integration_campaign' | translate }}!</span>
              </div>

            </li>
            <li class="layout-submenu-item" routerLinkActive="active-link" (click)="redirectMyProfile('companyDashboard')">
              <i class="pi pi-chart-bar icon icon-2 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'user.company-dashboard' | translate }}</p>
                <span>{{ 'user.important-information-at-glance' | translate}}!</span>
              </div>

            </li>
            <li *ngIf="UserLevel == 1" class="layout-submenu-item" routerLinkActive="active-link"
                (click)="redirectMyProfile('userLogs')">
              <i class="pi pi-list icon icon-2 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'user-logs' | translate }}</p>
                <span>{{ 'user-logs-details' | translate }}</span>
              </div>

            </li>
            <li *ngIf="isSuperAdmin" class="layout-submenu-item" routerLinkActive="active-link"
                (click)="redirectMyProfile('superAdmin')">
              <i class="pi pi-chart-line icon icon-2 p-pr-2 p-pl-2"></i>
              <div class="menu-text">
                <p>{{ 'user.super-admin-dashboard' | translate }}</p>
                <span>{{ 'user.for-developer-only' | translate }}</span>
              </div>

            </li>
            <li class="layout-submenu-footer">
              <button class="signout-button p-ml-2" (click)="logout()">{{ 'Logout' | translate }}</button>
            </li>
            </ul>
            </li>
            <!-- <li>
      <a href="#" class="layout-rightpanel-button" (click)="app.onRightPanelButtonClick($event)">
        <i class="pi pi-arrow-left"></i>
      </a>
    </li> -->
      </ul>

      <ul class="profile-mobile-wrapper">
        <li #mobileProfile class="topbar-item profile-item"
            [ngClass]="{'active-topmenuitem': app.activeTopbarItem === mobileProfile}">
          <a href="#" (click)="app.onTopbarItemClick($event,profile)">
            <span class="profile-image-wrapper">
              <img [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'" alt="{{loggedInUser}}" />
            </span>
            <span class="profile-info-wrapper">
              <h3>{{loggedInUser}}</h3>
              <span>{{userDept}}</span>
            </span>
          </a>
          <ul class="fadeInDown">
            <li class="profile-submenu-header">
              <div class="performance">
                <span>Weekly Performance</span>
                <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />
              </div>
              <div class="profile">
                <img [attr.src]="imageUrl? imageUrl: 'assets/mango/img/user.png'" alt="{{loggedInUser}}" width="45" />
                <h1>{{loggedInUser}}</h1>
                <span>{{userDept}}</span>
              </div>
            </li>
            <li>
              <i class="pi pi-list icon icon-1"></i>
              <div class="menu-text">
                <p>Tasks</p>
                <span>3 open issues</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-shopping-cart icon icon-2"></i>
              <div class="menu-text">
                <p>Payments</p>
                <span>24 new</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-users icon icon-3"></i>
              <div class="menu-text">
                <p>Clients</p>
                <span>+80%</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li class="layout-submenu-footer">
              <button class="signout-button" (click)="logout()">Log Out</button>
            </li>
          </ul>
        </li>
        </ul>
      <app-new-updates-available *ngIf="websocketService.isNewUpdatesAvailable"
          (onSubmitNewUpdates)="onSubmitNewUpdates($event)"></app-new-updates-available>
      </div>

  </div>
</div>
