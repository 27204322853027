<div class="makeDeposits">
    <div class="layout-content p-pt-0">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{ 'save_button' | translate }}" icon="fal fa-check"
                class="p-button-sm p-px-2 p-mx-2" pTooltip="Save Deposit" tooltipPosition="top"
                [disabled]="(!isFormValid)" (click)="saveData()"></button>
        </span>
        <div class="p-mx-2">
            <div class="p-col-12 p-pt-0">
                <div class="card p-pt-2 p-pb-0">
                    <form role="form" [formGroup]="makeDepositeForm" novalidate>
                        <div class="p-fluid p-formgrid p-grid p-col-8">
                            <div class="p-field p-col-3">
                                <label class="required-field label-text">{{ 'deposit-to' | translate }}</label>
                                <p-dropdown placeholder="{{ 'select-bank' | translate }}" [options]="banksList" placeholder="{{ 'select-bank' | translate }}"
                                    formControlName="BankID"></p-dropdown>
                            </div>

                            <div
                              class="
                                p-field
                                p-col-3
                              "
                            >
                              <label
                                class="
                                  required-field
                                  label-text
                                "
                              >
                                {{ 'deposit-date' | translate }}
                              </label>
                              <p-calendar
                                formControlName="DepositDate"

                                name="Ddate"

                                yearRange="2000:2030"
                                showButtonBar="true"

                                [monthNavigator]="true"
                                [yearNavigator]="true"

                                (onSelect)="
                                  verifySystemLocking( $event );
                                  validateDepositDate( );
                                "
                              >
                              </p-calendar>
                            </div>

                            <div class="p-field p-col-6">
                                <label class="label-text">{{ 'memo' | translate }}</label>
                                <input type="text" pInputText formControlName="DepositMemo">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="p-col-12 p-pt-0">
                <div class="card p-pt-0">
                    <p-table #dt [value]="banksDataSource" [rowHover]="true" [responsive]="true" [rows]="10"
                        dataKey="PaymentHeaderID" selectionMode="single" [showCurrentPageReport]="true"
                        [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [globalFilterFields]="['ClientName','PaymentDate','CompanyLocation','PaymentNote','PaymentType','CheckRef','PaymentAmount']"
                        (onHeaderCheckboxToggle)="checkBoxChanged();" [resizableColumns]="true"
                        [(selection)]="selectedItems" (onFilter)="onFilter($event)" [sortOrder]="1"
                        [rows]="banksDataSource.length" [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'"
                        [virtualScroll]="true">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'globalsearch' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{ 'clear' | translate }}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} Record(s) found." styleClass="p-ml-2"></p-message>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="width-6p p-text-center">{{ 'actions' | translate }}</th>
                                <th pSortableColumn="ClientName" pResizableColumn class="width-19p p-text-left">{{
                                    'client.name' | translate }}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="PaymentNote" pResizableColumn class="width-22p p-text-left">{{
                                    'Accounts.payment-memo' | translate }}
                                    <p-sortIcon field="PaymentNote"></p-sortIcon>
                                </th>
                                <th pSortableColumn="CompanyLocation" pResizableColumn class="width-10p p-text-left">{{
                                    'company_location' | translate }}
                                    <p-sortIcon field="CompanyLocation"></p-sortIcon>
                                </th>
                                <th pSortableColumn="PaymentDate" pResizableColumn class="width-10p p-text-left">{{
                                    'Accounts.payment-date' | translate }}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="PaymentType" pResizableColumn class="width-10p p-text-left">{{
                                    'Accounts.payment-type' | translate }}
                                    <p-sortIcon field="PaymentType"></p-sortIcon>
                                </th>
                                <th pSortableColumn="CheckRef" pResizableColumn class="width-11p p-text-left">{{
                                    'Accounts.check-reference' | translate }}
                                    <p-sortIcon field="CheckRef"></p-sortIcon>
                                </th>
                                <th pSortableColumn="PaymentAmount" pResizableColumn class="width-11p p-text-right">{{
                                    'Accounts.payment-amount' | translate }}
                                    <p-sortIcon field="PaymentAmount"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-3p p-text-center">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td class="width-6p">
                                    <div class="p-text-center">
                                        <i class="fal fa-undo p-mr-2 ic-red" (click)="reverseData(rowData)"
                                            tooltipPosition="top" pTooltip="Delete Payment"></i>
                                    </div>
                                </td>
                                <td class="width-19p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" showDelay="800"
                                    pTooltip="{{rowData.ClientName}}">
                                    {{rowData.ClientName}}
                                </td>
                                <td class="width-22p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" pTooltip="{{rowData.PaymentNote}}"
                                    showDelay="800">
                                    {{rowData.PaymentNote}}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" pTooltip="{{rowData.CompanyLocation}}"
                                    showDelay="800">
                                    {{rowData.CompanyLocation}}
                                </td>
                                <td class="width-10p p-text-left">
                                    {{ mangoUtils.formatDateString(rowData.PaymentDate) | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-10p p-text-left">
                                    {{rowData.PaymentType}}
                                </td>
                                <td class="width-11p p-text-left">
                                    {{rowData.CheckRef}}
                                </td>
                                <td class="width-11p p-text-right">
                                    ${{rowData.PaymentAmount}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="banksDataSource.length > 0">
                                <td colspan="8" class="p-text-right ic-red">Totals</td>
                                <td class="p-text-right ic-red">
                                    <strong>{{totalNumberAmt | currency:'USD':'symbol':'1.2-2'}}</strong>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="9">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
