<div class="project-list-tasks">
  <div class="layout-content">
    <div class="p-grid">
      <div class="p-col-12 p-pt-0">
        <div class="card p-pt-0 p-mt-1 p-mx-3">
          <div class="p-col-12 p-pt-0">
            <p-table #dt id="project-table" dataKey="ProjectDetailsID"
              styleClass="p-datatable-hoverable-rows custom-project-table" sortMode="multiple" sortField="ClientName"
              scrollDirection="both" scrollHeight="350px" [value]="projectTaskList" [columns]="selectedColumns"
              [rows]="selectedRowsPerPage" [responsive]="true" [rowHover]="true" [filterDelay]="0"
              [globalFilterFields]="globalFilterColumns" [sortOrder]="1" [multiSortMeta]="sortField"
              [(selection)]="selectedTasksItems" [reorderableColumns]="true" [resizableColumns]="true"
              columnResizeMode="expand" scrollDirection="both" (onSort)="onSort($event)"
              (onColReorder)="onColReorder($event)" (onFilter)="onFilter($event)" scrollWidth="100%">
              <ng-template pTemplate="caption">
                <div class="table-header">
                  <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text" placeholder="{{ 'Search-by' | translate }}"
                      (input)="onSearchInput($event)" />
                  </span>
                  <span *ngIf="taskListSearchQuery?.length > 0">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text" type="button"
                      icon="pi pi-times" tooltipPosition="top" pTooltip="Clear" (click)="clearSearchFilter()"></button>
                  </span>
                  <p-message *ngIf="filteredTasksItemsSize > -1" severity="success"
                    text="{{ filteredTasksItemsSize }} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                  </p-message>
                  <span class="pull-right">
                    <div class="p-formgroup-inline">
                      <div class="
                        p-field-radiobutton
                        p-pt-1
                      ">
                        <p-radioButton id="open" name="showRadio" value="true" [(ngModel)]="showOpenTasks"
                          [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onDisregardIsCompleteInOrder( 'open' )">
                        </p-radioButton>
                        <label for="input_outlined" class="p-pt-2">
                          {{ 'pm.show-all-open-tasks' | translate }}
                        </label>
                      </div>
                      <div class="
                        p-field-radiobutton
                        p-pt-1
                      ">
                        <p-radioButton id="ready" name="showRadio" value="false" [(ngModel)]="showOpenTasks"
                          [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onDisregardIsCompleteInOrder( 'ready' )">
                        </p-radioButton>
                        <label for="input_filled" class="p-pt-2">
                          {{ 'pm.show-tasks-ready-to-work' | translate }}
                        </label>
                      </div>
                      <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" class="p-mr-2"
                        selectedItemsLabel="{0} columns selected" placeholder="Choose Columns"
                        [style]="{ minWidth: '200px' }"></p-multiSelect>
                      <span *ngIf="selectedTasksItems?.length > 0">
                        <label class="label-text p-mr-2 p-mb-2" style="display: inline-block">{{ 'pm.batch_options' |
                          translate }}</label>
                        <button type="button" class="p-mr-4" pButton pRipple icon="pi pi-chevron-down"
                          (click)="menu.toggle($event)"></button>
                      </span>
                      <p-menu appendTo="body" #menu [popup]="true" [model]="taskButtonsDeleteList"></p-menu>
                      <button *ngIf="projectTaskList?.length > 0" pButton pRipple class="p-mr-2" type="button"
                        icon="pi pi-file-o" pTooltip="Export CSV of the current page" tooltipPosition="bottom"
                        (click)="exportCSVFile('current')"></button>
                      <button *ngIf="projectTaskList?.length > 0" pButton pRipple class="p-mr-2" type="button"
                        icon="pi pi-copy" pTooltip="Export to CSV of all projects" tooltipPosition="bottom"
                        (click)="exportCSVFile('all')"></button>
                      <button *ngIf="projectTaskList?.length > 0" pButton pRipple class="p-button-warning p-mr-2"
                        type="button" icon="pi pi-file-pdf" pTooltip="Export PDF of the current page"
                        tooltipPosition="bottom" (click)="exportPdf('current')"></button>
                      <button *ngIf="projectTaskList?.length > 0" pButton pRipple class="p-button-warning p-mr-2"
                        type="button" icon="pi pi-copy" pTooltip="Export to PDF of all projects"
                        tooltipPosition="bottom" (click)="exportPdf('all')"></button>
                    </div>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th class="width-2p p-text-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="width-6p p-text-left" pSortableColumn="ClientName">
                    {{ 'client' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="ClientName"></p-sortIcon>
                  </th>
                  <th class="width-4p p-text-center">{{ 'complete_task' | translate }}</th>
                  <th class="width-3p p-text-center">{{ 'task' | translate }} #</th>
                  <th class="width-6p p-text-left" pSortableColumn="TaskDescription">
                    {{ 'Task-Description' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="TaskDescription"></p-sortIcon>
                  </th>
                  <th class="width-6p p-text-left" pSortableColumn="Manager">
                    {{ 'Task-Manager' | translate }}
                    <p-sortIcon styleClass="p-ml-0" field="Manager"></p-sortIcon>
                  </th>

                  <th *ngFor="let col of columns.slice(1)" pReorderableColumn class="p-text-left {{ col.rowClass }}"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr>
                  <td class="width-2p p-text-center">
                    <p-tableCheckbox #checkboxTableP [value]="rowData" [index]="rowIndex"
                      (click)="mangoUtils.checkboxTable(checkboxTableP, $event, dt)"></p-tableCheckbox>
                  </td>
                  <td class="width-6p p-text-left p-text-nowrap p-text-truncate project-link cursor-hand"
                    pTooltip="{{ rowData.ClientName }}" showDelay="800" tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <span class="project-link cursor-hand"
                      routerLink="/project-management/{{ rowData['ClientID'] }}/clientDetails">{{ rowData.ClientName
                      }}</span>
                  </td>
                  <td class="width-4p p-text-center">
                    <p-checkbox name="taskname" [binary]="true" [(ngModel)]="rowData.isChecked" 
                      pTooltip="Complete Task" showDelay="800" tooltipPosition="top" 
                      (onChange)="onChecked($event, rowData, rowIndex)" [disabled]="disableCheck">
                    </p-checkbox>
                  </td>
                  <td class="width-3p p-text-center p-px-2">
                    <div class="badge-number bg-blue">{{ rowData.TaskRow }}</div>
                  </td>
                  <td class="width-6p p-text-left p-text-nowrap p-text-truncate" id="remove-border"
                    pTooltip="{{ rowData.TaskDescription }}" showDelay="800" tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    {{ rowData.TaskDescription }}
                  </td>
                  <td class="width-6p p-text-left p-text-nowrap p-text-truncate" id="remove-border"
                    pTooltip="{{ rowData.Manager }}" showDelay="800" tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    {{ rowData.Manager }}
                  </td>

                  <td *ngFor="let col of columns.slice(1)" class="{{ col.rowClass }} p-text-truncate" [pTooltip]="
                      col.field === 'SortableDueDate'
                        ? getToolTip(rowData)
                        : col?.field?.indexOf('Date') > -1
                        ? ''
                        : rowData[col.field]
                    " showDelay="800" tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                    <span *ngIf="
                        rowData.compareTaskDueDate &&
                        rowData.compareTaskDueDate < todayDate &&
                        col.field === 'compareTaskDueDate'
                      " class="dueDate_critical">
                      {{ rowData.compareTaskDueDate | date: 'MM/dd/yyyy' : 'UTC' }}
                    </span>
                    <span *ngIf="
                        rowData.compareTaskDueDate &&
                        rowData.compareTaskDueDate >= todayDate &&
                        col.field === 'compareTaskDueDate'
                      ">
                      {{ rowData.compareTaskDueDate | date: 'MM/dd/yyyy' : 'UTC' }}
                    </span>
                    <span *ngIf="
                        rowData.compareTaskDueDate == null &&
                        col.field === 'compareTaskDueDate'
                      ">
                      -
                    </span>
                    <span *ngIf="col.field === 'TemplateName'" class="project-link cursor-hand"
                      (click)="redirectToProjectDetails(rowData)">
                      {{ rowData.TemplateName }}
                    </span>
                    <span *ngIf="col.field === 'staffLabel'" class="p-text-nowrap pTag">
                      {{ rowData.staffLabel }}
                    </span>
                    <span *ngIf="col.field === 'Tags'">
                      <span *ngFor="let tag of rowData['Tags']">
                        <span *ngIf="findTagByValue(tag, 'label') !== ''" class="p-text-nowrap pTag"
                          pTooltip="{{ findTagByValue(tag, 'label') }}" showDelay="800"
                          tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                          [style.background-color]="findTagByValue(tag, 'color')">
                          &nbsp;
                        </span>
                      </span>
                    </span>
                    <span pTooltip="{{ rowData.DateReceived | date: 'MM/dd/yyyy' }}"
                      *ngIf="col.field === 'DateReceived'">
                      {{ rowData.DateReceived | date: 'MM/dd' }}
                    </span>
                    <span *ngIf="col.field === 'SortableDueDate'">
                      {{ (rowData.ExtensionDate ? 'Ext. ' + (rowData.ExtensionDate | date:
                      'MM/dd/yyyy') : rowData.DueDate | date: 'MM/dd/yyyy') || '-' }}
                    </span>
                    <span *ngIf="col.field === 'Status'"
                      class="flag project-summary-{{ rowData.statusClass | lowercase }}">
                      {{ rowData.Status }}
                    </span>
                    <span *ngIf="col.field === 'StaffNames'"> {{ rowData.StaffNames }} </span>
                    <span *ngIf="col.field === 'GroupDescriptionValues'">
                      {{ rowData.GroupDescriptionValues }}
                    </span>
                    <span *ngIf="col.field === 'CompanyLocation'">
                      {{ rowData.CompanyLocation }}
                    </span>
                    <span *ngIf="col.field === 'EngagementName'">
                      {{ rowData.EngagementName }}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="projectTaskList.length > 0">
                  <td class="p-text-center" colspan="1">
                    <span> {{ selectedTasksItems?.length || 0 }} Selected </span>
                  </td>
                  <td colspan="11" class="p-text-right">
                    <span [innerHTML]="getSortValues()"></span>
                  </td>
                  <td class="p-text-center" colspan="1">
                    <span> {{ projectTaskList?.length || 0 }} Item/s </span>
                  </td>
                  <td class="p-text-center" colspan="1">
                    <span> {{ projectTaskListTotalCount || 0 }} Total Items </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="10">
                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                      styleClass="p-px-2"></p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <p-paginator #p *ngIf="projectTaskListTotalCount > 0" [totalRecords]="projectTaskListTotalCount"
              [rows]="selectedRowsPerPage" [rowsPerPageOptions]="[10, 25, 50]"
              (onPageChange)="onTablePageChange($event)">
            </p-paginator>
          </div>
        </div>
      </div>
    </div>
    <p-sidebar position="right" [(visible)]="displayFilterOption.dialogStatus" [style]="{
        'min-width': '30%',
        'min-height': '100%',
        background: '#8e8f92',
        overflow: 'auto'
      }" (onHide)="onCloseFilter()">
      <div class="card" style="min-height: calc(100vh - 450px)">
        <div>
          <div class="p-field p-fluid">
            <span class="label-text">{{ 'client' | translate }}</span>
            <p-autoComplete [(ngModel)]="selTaskClient" [ngModelOptions]="{ standalone: true }"
              [suggestions]="filteredClients" autocomplete="off" (completeMethod)="filterClients($event)"
              field="ClientName" (onSelect)="handleSelectClick($event.value)" ngDefaultControl
              placeholder="{{ 'Search-Clients' | translate }}" (onFocus)="$event.target.select()" [dropdown]="true"
              required>
              <ng-template let-client pTemplate="item">
                <div>
                  {{ client.ClientName }}{{ client.ClientNumber ? ' - ' + client.ClientNumber : ''
                  }}
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="p-field p-fluid">
            <label class="label-text">{{ 'Projects' | translate }}</label>
            <p-dropdown [options]="projectsList" [(ngModel)]="taskObj.TemplateName"
              (onChange)="onChangeFilters($event, 'templateName')" name="itemTaskProjectSelected" [filter]="true"
              id="itemTaskProjectSelected" #itemTaskProjectSelected="ngModel"
              [ngModelOptions]="{ standalone: true }"></p-dropdown>
          </div>
          <div class="p-field p-fluid">
            <label class="label-text p-text-left">Task Date Range</label>
            <p-dropdown [options]="dueDateList" [(ngModel)]="taskObj.dueDateID"
              (onChange)="onChangeFilters($event, 'dueDate')" scrollHeight="350px" name="itemdueTaskDateSelected"
              id="itemdueTaskDateSelected" #itemdueTaskDateSelected="ngModel"
              [ngModelOptions]="{ standalone: true }"></p-dropdown>
          </div>
          <div class="p-field p-fluid" *ngIf="taskObj.dueDateID === 'custom'">
            <div class="p-d-flex p-jc-between">
              <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline">
                <label class="label-text p-mr-3 p-mb-2">{{ 'date-from' | translate }}</label>
                <p-calendar (onSelect)="onChangeFilters($event, 'dateFrom')"
                  (onClearClick)="onChangeFilters($event, 'dateFrom')" (onBlur)="onChangeFilters($event, 'dateFrom')"
                  [monthNavigator]="true" [ngModelOptions]="{ standalone: true }" [yearNavigator]="true"
                  yearRange="2000:2090" [(ngModel)]="DateFrom" [style]="{ width: '125%' }"></p-calendar>
              </div>
              <div class="p-col-6 p-pl-0 p-py-0 p-formgroup-inline">
                <label class="label-text p-mr-3 p-mb-2">{{ 'date-to' | translate }}</label>
                <p-calendar (onSelect)="onChangeFilters($event, 'dateTo')"
                  (onClearClick)="onChangeFilters($event, 'dateTo')" (onBlur)="onChangeFilters($event, 'dateTo')"
                  [monthNavigator]="true" [ngModelOptions]="{ standalone: true }" [yearNavigator]="true"
                  yearRange="2000:2090" [(ngModel)]="DateTo" [style]="{ width: '125%' }"></p-calendar>
              </div>
            </div>
          </div>
          <div class="p-field p-fluid">
            <label class="label-text p-text-left">{{ 'status' | translate }}</label>
            <p-multiSelect [options]="statusList" [(ngModel)]="taskObj.statusIDs"
              (onChange)="onChangeFilters($event, 'status')" [ngModelOptions]="{ standalone: true }"
              selectedItemsLabel="{0} status selected" placeholder="All Active">
              <ng-template let-item pTemplate="selectedItem">
                <span class="p-text-center {{ item.color | lowercase }}">{{ item.label }}</span>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="ui-helper-clearfix">
                  <div class="p-text-center drop_{{ item.color | lowercase }}">
                    {{ item.label }}
                  </div>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
          <div class="p-field p-fluid">
            <label class="label-text">{{ 'Assigned-User' | translate }}</label>
            <p-dropdown [options]="AllStaffsTypes" [(ngModel)]="taskObj.assignedUserId"
              (onChange)="onChangeFilters($event, 'assigned')" name="itemTaskAssignedSelected"
              id="itemTaskAssignedSelected" #itemTaskAssignedSelected="ngModel"
              [ngModelOptions]="{ standalone: true }"></p-dropdown>
          </div>
          <div class="p-field p-fluid">
            <label class="label-text">{{ 'Client_Group' | translate }}</label>
            <p-dropdown [options]="groupList" [(ngModel)]="taskObj.clientGroup" placeholder="All"
              (onChange)="onChangeFilters($event, 'clientGroup')"></p-dropdown>
          </div>
          <div class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns">
            <button pButton pRipple class="p-mr-2" type="button" icon="fal fa-sync" label="{{ 'fetch' | translate }}"
              [disabled]="taskObj.dueDateID === 'custom' && (!DateTo || !DateFrom)" (click)="fetchData()"></button>
            <button pButton pRipple class="p-mr-2" type="button" icon="fal fa-undo" label="{{ 'Reset' | translate }}"
              (click)="onResetFilters()"></button>
            <button pButton pRipple class="p-mr-2" type="button" icon="fal fa-sync"
              label="{{ 'cancel_button' | translate }}" (click)="onCloseFilter()"></button>
          </div>
        </div>
      </div>
    </p-sidebar>
  </div>
  <p-dialog [(visible)]="showBatchUpdateTasksModal" [modal]="true" [style]="{ width: '35vw' }" [maximizable]="false"
    showEffect="fade" [draggable]="true" [resizable]="false" (onHide)="closeBatchUpdateTasksModal()">
    <p-header>
      {{ 'pm.Batch_Update_Tasks' | translate }} - {{ selectedTasksItems.length }} {{ 'selected' |
      translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <form #batchUpdateTasksForm="ngForm" class="p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-col-6 p-mt-4">
            <p-checkbox [binary]="true" label="{{ 'Assigned' | translate }}" class="label-text"
              [(ngModel)]="formObj.isAssigned" name="assigned"></p-checkbox>
          </div>
          <div class="p-col-6 p-mt-3">
            <p-multiSelect [(ngModel)]="formObj['staffIds']" #staffIds="ngModel" [options]="staffListBatchUpdate"
              placeholder="{{ 'assign_user' | translate }}" (onChange)="batchUpdateTasksFormTouched = true;"
              appendTo="body" id="itemUserAssigned" name="itemUserAssigned" [ngModelOptions]="{ standalone: true }"
              [disabled]="!formObj.isAssigned"></p-multiSelect>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-col-6 p-mt-4">
            <p-checkbox [binary]="true" label="{{ 'due-date' | translate }}" class="label-text"
              [(ngModel)]="formObj.isDueDate" name="isDueDate"></p-checkbox>
          </div>
          <div class="p-col-6 p-mt-3">
            <p-calendar [monthNavigator]="true" [yearNavigator]="true" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="formObj.dueDate" appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy"
              (ngModelChange)="batchUpdateTasksFormTouched = true" showButtonBar="true" [disabled]="!formObj.isDueDate">
            </p-calendar>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-col-6 p-mt-4">
            <p-checkbox [binary]="true" label="{{ 'description' | translate }}" class="label-text"
              [(ngModel)]="formObj.isMemo" name="isMemo"></p-checkbox>
          </div>
          <div class="p-col-6 p-mt-3">
            <input pInputText type="text" [(ngModel)]="formObj.memo"
              (ngModelChange)="batchUpdateTasksFormTouched = true" [ngModelOptions]="{ standalone: true }"
              (focus)="$event.target.select()" [disabled]="!formObj.isMemo" />
          </div>
        </div>
        <div class="p-formgroup-inline p-mb-4">
          <div class="p-col-6 p-mt-4">
            <p-checkbox [binary]="true" label="{{ 'repeat' | translate }}" class="label-text"
              [(ngModel)]="formObj.updateIsRepeat" name="updateIsRepeat">
            </p-checkbox>
          </div>
          <div class="p-col-6 p-mt-3">
            <p-checkbox [binary]="true" class="label-text" (onChange)="batchUpdateTasksFormTouched = true"
              [(ngModel)]="formObj.isRepeat" name="isRepeat" [disabled]="!formObj.updateIsRepeat">
            </p-checkbox>
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <button type="button" pButton pRipple label="{{ 'pm.update_tasks' | translate }}" (click)="batchUpdateTasks()"
        class="pull-right" [disabled]="!batchUpdateTasksFormTouched"></button>
      <button type="button" pButton pRipple label="{{ 'cancel_button' | translate }}"
        (click)="showBatchUpdateTasksModal = false" class="pull-right"></button>
    </ng-template>
  </p-dialog>
</div>