import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "@app/_helpers/api-constants";
import { EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { forkJoin, timer } from "rxjs";
import Swal from "sweetalert2";
import { SharedComponentsService } from "../shared-components-service";
declare let numeral: any;

@Component({
  selector: "app-batch-options-time",
  templateUrl: "./batch-options-time.component.html",
})

export class BatchOptionsTimeComponent implements OnInit {
  batchButtons: MenuItem[];
  showReassignDialog: boolean = false;
  showUpdateDialog: boolean = false;
  isFormValid: boolean = false;
  isShowUpdateBilledAmtDialog: boolean = false;
  @Input() selOpenTimeRecords: any;
  @Input() selOpenExpRecords: any;
  @Input() deleteWuwdAndBilledAmt: boolean;
  @Input() showApproveOption: boolean;
  @Input() showUpdateBilledAmt: boolean;
  @Input() defaultClient: any;
  @Output() onUpdate: any = new EventEmitter();
  @Output() onUpdateBilledAmt: any = new EventEmitter<{
    newBilledAmt: number;
  }>();

  formObj: any = {
    SelClient: null,
    ProjectMasterID: null,
    EngagementTypeID: null
  };

  batchUpdateFormObj: any = {
    billingRate: "0",
    billedAmount: "0"
  };

  clientList: any = [];
  filteredClients: any = [];
  projectsList: any = [];

  intervalid: any;
  timeexp
  companySetting: any;
  companyId;

  constructor(
    private translate: TranslateService,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    private http: HttpClient,
    private router: Router,
    private sharedSrvc: SharedComponentsService
  ) {
    this.mangoAPISrvc.applyLanguage();
  }

  ngOnInit(): void {
    this.batchButtons = [
      {
        label: "Delete Selected Items",
        icon: "fal fa-trash-alt",
        command: () => {
          this.deleteItems();
        },
      },
      {
        label: "Update Billing Rate",
        icon: "fal fa-pencil",
        command: () => {
          this.showUpdateForm();
        },
      },
      {
        label: "Reassign Selected Items",
        icon: "fal fa-pencil",
        command: () => {
          this.showReassignForm();
        },
      },
    ];

    if(this.showUpdateBilledAmt) {
      this.batchButtons.splice(1, 0, {
        label: "Update Billed Amount",
        icon: "fal fa-pencil",
        command: () => {
          this.showUpdateBilledAmtDialog();
        },
      })
    }

    if(this.showApproveOption) {
      this.batchButtons.unshift({
        label: "Approve Selected Items",
        icon: "fal fa-check",
        command: () => {
          this.approveItems();
        },
      })
    }

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);

    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
  }

  deleteItems(): void {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant('delete_selected_time_records'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_Proceed"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.batchDeleteItems().subscribe(
          (data) => {
            this.sharedSrvc.setTimeEntryDialogData(true);
            this.mangoAPISrvc.notify(
              "success",
              "Deleted!",
              AppConstants.deleteMessage
            );
          },
          (error) => {
            this.mangoAPISrvc.notify(
              "error",
              "Error!",
              AppConstants.deleteErrorMsg
            );
            this.mangoAPISrvc.showLoader(false);
          }
        );
      }
    });
  }

  batchDeleteItems() {
    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);
   

    const observableBatch = [];
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", this.encrDecSrvc.getObject("token"));
    const selectedItemList = [
      ...this.selOpenTimeRecords,
      ...this.selOpenExpRecords,
    ];
    for (let i = 0; i < selectedItemList.length; i++ ) {
      const selectedItem = selectedItemList[i];
      if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(selectedItem.Ddate) <= lastDayOfMonth) {
        this.mangoAPISrvc.notify('error', this.translate.instant('error'), "Unable to delete items since the month is locked.")
        continue;
      }
      observableBatch.push(
        this.http.delete(
          `${environment.API_URL}/company/timeSheet/${selectedItem.SlipMasterID}`,
          { headers: headers }
        )
      );
    }

    if (observableBatch.length == 0) this.mangoAPISrvc.showLoader(false);
    return forkJoin(observableBatch);
  }

  showReassignForm(): void {
    this.showReassignDialog = true;
    this.formObj.SelClient = this.defaultClient
    this.handleSelectClick(this.defaultClient)
  }

  showUpdateForm(): void {
    this.showUpdateDialog = true
  }

  showUpdateBilledAmtDialog(): void {
    this.isShowUpdateBilledAmtDialog = true
  }

  onBatchUpdateBilledAmount() {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("batch_update_billed_amount"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_Proceed"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.onUpdateBilledAmt.emit({
          newBilledAmt: numeral(this.batchUpdateFormObj.billedAmount).value()
        })
        this.onCloseUpdateBilledAmtDialog()
      }
    })
  }

  onBatchUpdate() {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("batch_update_billing_rate"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_Proceed"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        const {observableBatch} = this.generateItemsToUpdate(true)
        this.batchUpdateItems(observableBatch, true).subscribe(
          (data) => {
            this.onUpdate.emit(true)
            this.mangoAPISrvc.notify("success", "Updated!", AppConstants.updateMsg);
            this.mangoAPISrvc.showLoader(false);
            this.onCloseBatchUpdateForm();
          },
          (error) => {
            this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
            this.mangoAPISrvc.showLoader(false);
            this.onCloseBatchUpdateForm();
          }
        );
      }
    })
  }

  approveItems(): void {
    const items = [...this.selOpenTimeRecords, ...this.selOpenExpRecords];
    const idForApprove = items.map((item) => item.SlipMasterID);

    const obj = { slipMasterIdArr: idForApprove };
    this.mangoAPISrvc.approveTSE(obj).subscribe(
      (data) => {
        this.sharedSrvc.setTimeEntryDialogData(true);
      },
      (err) => {}
    );
  }

  onReassign(): void {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("reassign_selected_time_records"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_Proceed"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        const {observableBatch, hasBillableUpdated}= this.generateItemsToUpdate()
        if(hasBillableUpdated) {
          Swal.fire({
            title: 'Confirmation',
            text: this.translate.instant('batch_reassign_message'),
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: this.translate.instant('batch_reassign_confirm'),
            denyButtonText: this.translate.instant('batch_reassign_deny'),
            cancelButtonText: this.translate.instant('cancel_button')
          }).then((result) => {
            if (result.isConfirmed) {
              this.updateBillableTimeRecords(observableBatch)
            } else if(result.isDenied) {
              const itemToUpdate = observableBatch.map((item) => {
                if(item.isBillableUpdated) {
                  return {
                    ...item,
                    Billable: !item.Billable
                  }
                } else return item
              })
              this.updateBillableTimeRecords(itemToUpdate)
            } else {
              this.onCloseReassignForm();
              this.mangoAPISrvc.showLoader(false)
            }
          })
        } else {
          this.updateBillableTimeRecords(observableBatch)
        }
      }
    });
  }

  updateBillableTimeRecords(itemsToUpdate) {
    this.batchUpdateItems(itemsToUpdate, false).subscribe(
      (data) => {
        this.sharedSrvc.setTimeEntryDialogData(true);
        this.mangoAPISrvc.notify(
          "success",
          "Updated!",
          AppConstants.updateMsg
        );
        this.onCloseReassignForm();

      },
      (error) => {
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
        this.onCloseReassignForm();
      }
    );
  }

  formatNumber(name) {
    name = name.toString();
    if (name != undefined && name != '') {
      if (name.includes(".")) {
        const index = name.indexOf('.');
        if (name.substr(index + 1).length == 0) {
          name = name + '00';
        } else if (name.substr(index + 1).length == 1) {
          name = name + '0';
        }
      } else {
        name = name + '.00';
      }
      return name;
    }
  }

  getMarkSlipsBilled(clientID) {
    const clientSelected = this.clientList.find(c => c.ClientID === clientID);
    return clientSelected?.MarkSlipsBilled
  }

  calculateBillableAmount(record) {
    if (record.BillingRate) record.BillingRate = record.BillingRate.replace("$", "");

    if (!record.TotalTime) {
      return;
    }

    record.TotalTime = numeral(numeral(record.TotalTime).value()).format("0.00");
    if (
      !record.TotalTime ||
      record.TotalTime.toString().indexOf(".") < 0 ||
      record.TotalTime.toString().split(".").length > 2
    ) {
      record.TotalTime = 0.0;
    }

    const splitTime = record.TotalTime.split(".");
    let splitMinutes: any = parseInt(splitTime[1]) * 0.6;
    splitMinutes = splitMinutes < 10 ? "0" + splitMinutes + " minutes" : splitMinutes + " minutes";
    record.TotalTime = this.formatNumber(record.TotalTime);

    if (record.TotalTime && record.BillingRate) {
      if (record.BillingRate.replace("$", "") == 0)
        if (this.getMarkSlipsBilled(record.ClientID) && this.timeexp?.isAdminTimeZero)
          record.StandardAmount = "0.00";
        else record.StandardAmount = record.BillingRate;
      else if (record.isFlatFee) {
      } else {
        if (this.getMarkSlipsBilled(record.ClientID) && this.timeexp?.isAdminTimeZero)
          record.StandardAmount = "0.00";
        else
          record.StandardAmount = numeral(
            numeral(record.TotalTime).value() * numeral(record.BillingRate).value()
          ).format("0,0.00");
      }
    }
  }

  generateItemsToUpdate(isUpdateBillingRate?: boolean) {
    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)
    ).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    const observableBatch = [];
    const selectedItemList = [...this.selOpenTimeRecords, ...this.selOpenExpRecords];

    for (let i = 0; i < selectedItemList.length; i++) {
      const selectedItem = selectedItemList[i];
      if (
        this.companySetting.isEnableSystemLocking &&
        dayToday >= lockDay &&
        new Date(selectedItem.Ddate) <= lastDayOfMonth
      ) {
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          "Unable to update items since the month is locked."
        );
        continue;
      }

    }
    let updatedBillableCount = 0
    const finalList = selectedItemList.map((item) => {
      if (isUpdateBillingRate) {
        if(item.IsTimeRecord !== "X") {
          item['BillingRate'] = this.batchUpdateFormObj.billingRate
          this.calculateBillableAmount(item);
        }
        return {
          ...item,
          StandardAmount: numeral(item?.StandardAmount).value()
        };
      } else {
        const isProjectBillable = this.projectsList.filter(
          (item) => item.value === this.formObj.ProjectMasterID
        )[0]?.isBillable;
        const isSlipBillable = this.formObj.SelClient?.Billable
          ? true
          : isProjectBillable ?? false;
        const isBillableUpdated = isSlipBillable !== item.Billable
        if(isBillableUpdated) {
          updatedBillableCount++
        }
        return {
          ...item,
          isBillableUpdated,
          Billable: isSlipBillable,
          Billed: this.formObj.SelClient?.MarkSlipsBilled ? true : item.Billed,
          BudgetsID: null,
          ddmProjectDetailID: null,
          ddmProjectHeaderID: null,
          StandardAmount: item?.StandardAmount
            ? item?.StandardAmount?.toString()
            : item?.StandardAmount,
          WriteUpDown: this.deleteWuwdAndBilledAmt ? null : item?.WriteUpDown,
          BilledAmount: this.deleteWuwdAndBilledAmt ? null : item?.BilledAmount,
          ClientID: this.formObj.SelClient.ClientID,
          ProjectMasterID: this.formObj.ProjectMasterID,
          EngagementTypeID: this.formObj.EngagementTypeID,
        };
      }
    });

    finalList.forEach((selectedItem) => {
      observableBatch.push(selectedItem);
    });
    
    return {observableBatch, hasBillableUpdated: updatedBillableCount > 0}
  }

  batchUpdateItems(observableBatch, isUpdateBillingRate?: boolean) {
    if (observableBatch.length == 0) {
      this.mangoAPISrvc.showLoader(false);
      return forkJoin([]);
    }

    if(isUpdateBillingRate) {
      const rowsToUpdate = observableBatch.map((row) => {
        const { SlipMasterID, StandardAmount, BillingRate, ...others } = row;
        return {
          SlipMasterID,
          StandardAmount,
          BillingRate,
        };
      });

      return forkJoin([
        this.mangoAPISrvc.bulkUpdateBillingRate({
          rowsToUpdate
        }),
      ]);
    } else {
      return forkJoin([
        this.mangoAPISrvc.bulkTimeSheetSave(this.companyId,{
          rowsToInsert: [],
          rowsToUpdate: observableBatch,
          rowsToDelete: [],
        }),
      ]);
    }
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      this.filteredClients = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) >
            -1 &&
          client["ContactRecord"] != true &&
          client["Inactive"] != true
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }
        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  handleSelectClick(itemData, isProject?): void {
    this.formObj.ProjectMasterID = null;
    this.formObj.EngagementTypeID = null;

    if(isProject) {
      const proj = this.projectsList.filter((proj) => itemData.value == proj.value)[0];
      this.formObj.ProjectMasterID = proj.value
      this.formObj.EngagementTypeID = proj.EngagementTypeID
    } else
      this.getProjects(itemData);
  }

  getProjects(itemData) {
    this.projectsList = [];
    if (itemData?.ClientID) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getProjectsByClientId(itemData.ClientID).subscribe(
        (data: any) => {
          this.mangoAPISrvc.showLoader(false);
  
          this.projectsList = data
            .filter((project) => project.Inactive == false)
            .map((project) => {
              return {
                label: project.EngagementName,
                value: project.ProjectMasterID,
                EngagementTypeID: project.EngagementTypeID,
                isBillable: project.isBillable,
              };
            });
  
          if (this.projectsList.length === 0) {
            Swal.fire({
              title: this.translate.instant("Client_Engagements_Required"),
              html: this.translate.instant(
                "billing.there_are_no_engagements_setup"
              ),
              icon: "warning",
              showCancelButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              confirmButtonText: this.translate.instant("Go_to_Client"),
              cancelButtonText: this.translate.instant("no_cancel"),
            }).then((result) => {
              if (result.value) {
                this.encrDecSrvc.addObject(
                  AppConstants.selectedClientRecord,
                  this.clientList.filter(
                    (client) => client.ClientID === itemData["ClientID"]
                  )[0]
                );
  
                this.encrDecSrvc.addObject(
                  AppConstants.clientID,
                  itemData["ClientID"]
                );
                this.encrDecSrvc.addObject(
                  AppConstants.ClientName,
                  itemData["ClientName"]
                );
                this.mangoAPISrvc.showLoader(true);
                this.mangoAPISrvc
                  .getAllDataCounts(itemData["ClientID"])
                  .subscribe((data) => {
                    this.encrDecSrvc.addObject(
                      AppConstants.allDataCountsForClient,
                      data
                    );
                    this.mangoAPISrvc.fireClientView(true);
                    this.router.navigate([
                      AppConstants.clientRoutePath +
                        "/" +
                        AppConstants.viewRoutePath,
                    ]);
                    //this.mangoAPISrvc.showLoader(false);
                  });
              } else return;
            });
          } else if (this.projectsList.length === 1) {
            this.formObj.ProjectMasterID = this.projectsList[0].value;
            this.formObj.EngagementTypeID = this.projectsList[0].EngagementTypeID;
          }
        },
        () => {
          this.mangoAPISrvc.showLoader(false);
        }
      );
    }
   
  }

  onCloseReassignForm(): void {
    this.showReassignDialog = false;
    this.formObj = {
      SelClient: null,
      ProjectMasterID: null,
      EngagementTypeID: null
    }
  }

  onCloseBatchUpdateForm(): void {
    this.showUpdateDialog = false;
    this.batchUpdateFormObj.billingRate = 0;
  }

  onCloseUpdateBilledAmtDialog(): void {
    this.isShowUpdateBilledAmtDialog = false;
    this.batchUpdateFormObj.billedAmount = 0;
  }

  formatBillingRate() {
    let enteredValue = numeral(this.batchUpdateFormObj.billingRate).value();
    enteredValue = enteredValue ? enteredValue : 0;
    this.batchUpdateFormObj.billingRate = '$' + numeral(enteredValue).format('0,0.00');
  }

  formatBilledAmount() {
    let enteredValue = numeral(this.batchUpdateFormObj.billedAmount).value();
    enteredValue = enteredValue ? enteredValue : 0;
    this.batchUpdateFormObj.billedAmount = '$' + numeral(enteredValue).format('0,0.00');
  }
}
